import React, {useEffect} from "react";

export const Redirect = ({url}) => {

    useEffect(() => {
        window.location.replace(url);
        return () => {};
    }, []);

    return null;
};
