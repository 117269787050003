import React, { useEffect, useState } from "react";
import { API } from "./services/apiKey";
import {
  capitalizarPrimeraLetra,
  capitalizarFecha,
  conversor,
} from "./utils/date";
import axios from "axios";
import moment from "moment-timezone";
import Moment from "moment/locale/es";
import { Col, Container, Row } from "react-bootstrap";

export const WeatherAPI = ({ masInfoInter, masInfoExt, setMasInfoExt, greenhouse }) => {
  const [location, setLocation] = useState({});

  // Gelocalización y traer datos de la API
  useEffect(() => {
    if (greenhouse.lat && greenhouse.lon){
      axios
          .get(
            `${API.url}?lat=${greenhouse.lat}&lon=${greenhouse.lon}&cnt=5&units=metric&APPID=${API.key}&lang=es`
          )
          .then((result) => {
            setLocation(result.data);
          })
          .catch((error) => {
            console.log(error);
          });
    }
    else{
      navigator.geolocation.getCurrentPosition((position) => {
        axios
          .get(
            `${API.url}?lat=${position.coords.latitude}&lon=${position.coords.longitude}&cnt=5&units=metric&APPID=${API.key}&lang=es`
          )
          .then((result) => {
            setLocation(result.data);
          })
          .catch((error) => {
            console.log(error);
          });
      });
    }
  }, []);

  return (
    <Container>
      <Row className="">
        {location.name ? (
          <div>
            <Col className="d-flex justify-content-around  divMov py-3">
              <div className="divTemp text-secondary small">
                <p className=""><i className='bi bi-thermometer-half'></i> {Math.round(location.main.temp)}ºC</p>
                <p className="">
                  <i className='bi bi-thermometer-high'></i> Max: <span style={{ "color": 'red' }}>{Math.round(location.main.temp_max)} ºC</span>
                </p>
                <p className="">
                  <i className='bi bi-thermometer-low'></i> Min: <span style={{ "color": 'blue' }}>{Math.round(location.main.temp_min)} ºC</span>
                </p>
                <p className="tempHour">
                  <i className='bi bi-droplet'></i> Humedad: {location.main.humidity}%
                </p>
                <p className="tempHour">
                  <i className='bi bi-wind'></i> Viento: {Math.round(location.wind.speed)} m/s
                </p>
              </div>
              <span className="borderDivDate"></span>
              <div className="divLocation">
                <p className="small">{location.name}</p>
                <p className="small">
                  {capitalizarFecha(
                    capitalizarPrimeraLetra(moment().format("dddd, D MMMM"))
                  )}
                </p>

                <div className="d-flex align-items-end divIconMov">
                  <img
                    className="iconosTiempo2"
                    src={conversor(location.weather[0].icon)}
                  />

                  <p className='small'>
                    {capitalizarPrimeraLetra(location.weather[0].description)}
                  </p>
                </div>
              </div>
            </Col>
            {/* <Col className=" ">
              <div className="d-flex justify-content-around text-secondary small" style={{ "padding": "0 7px" }}>
                <span>Mañana: {Math.round(weather.feels_like.morn)} ºC</span>
                <span className="text-secondary">|</span>
                <span> Tarde: {Math.round(weather.feels_like.eve)} ºC</span>
                <span className="text-secondary">|</span>
                <span> Noche: {Math.round(weather.feels_like.night)} ºC</span>
                <hr />
              </div>
            </Col> */}
{/* 
            {masInfoExt ? (
              <Col>
                <br />
                {location
                  ? location.list.map((data, index) => {
                    return (
                      <div
                        className="d-flex justify-content-around divDate1 align-items-center text-secondary small"
                        key={index}
                      >
                        <span className="spanDay small">
                          {capitalizarPrimeraLetra(
                            moment
                              .unix(data.dt)
                              .tz("Europe/Madrid")
                              .format("dddd D")
                          )}
                        </span>
                        <img
                          className="iconosTiempo"
                          src={conversor(data.weather[0].icon)}
                        />

                        <span className='small'>Viento: {Math.round(data.speed)} m/s</span>
                        <span className='small'>{Math.round(data.temp.day)}ºC</span>
                      </div>
                    );
                  })
                  : null}
              </Col>
            ) : null} */}
            {/* <button
              className="btn btn-sm"
              onClick={() => {
                setMasInfoExt(!masInfoExt);
              }}
            >
              <i
                className={
                  masInfoExt
                    ? "bi bi-dash-square"
                    : "bi bi-plus-square"
                }
              >
              </i>
            </button> */}
          </div>
        ) : 
        <div className=''><br/>
          <p className='text-secondary small'>Cargando datos metorológicos...</p>
        </div>}

      </Row>
    </Container>
  );
};
