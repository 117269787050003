import React, { useEffect, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import { AppRoutes } from "./routes/AppRoutes";
import Cookies from "js-cookie";
//import "./assets/scss/_variables.scss";
//import "./assets/scss/_utility.scss";
import "./assets/scss/style.scss";

function App() {
  const [isLogged, setIsLogged] = useState(false);

  useEffect(() => {
    const token = Cookies.get("token");
    if (token) {
      setIsLogged(true);
    }
  }, []);

  return (
    <>
      <BrowserRouter>
        <AppRoutes isLogged={isLogged} setIsLogged={setIsLogged} />
      </BrowserRouter>
    </>
  );
}

export default App;
