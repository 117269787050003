import React from "react";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useParams } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import { userAPI } from '../../api';
import config from '../../config.json';
import Cookies from 'js-cookie';
import "./edituser.scss";

export const EditUser = ({
  user,
  profileChange,
  setProfileChange,
}) => {
  
  const [userType, setUserType] = useState();
  const [userId, setUserId] = useState();
  const [password1, setPassword1] = useState('');
  const [passMatch, setPassMatch] = useState(false);
  const [passModal, setPassModal] = useState(false);
  const [profileImg, setProfileImg] = useState('default.jpg');
  const token = Cookies.get("token");
  const { user_id } = useParams();
  const handleClose = () => setPassModal(false);
  const handleShow = () => setPassModal(true);

  const navigate = useNavigate();
  var newpassword = "";

  // Creamos el estado para guardar los datos intoducidos
  const [editUser, setEditUser] = useState({
    name: "",
    lastname: "",
    dni: "",
    email: "",
    phone: "",
    address: "",
  });
  const [file, setFile] = useState();

  let [ params, setParams ] = useSearchParams();
  useEffect(() => {
    if (params.get('newpass') != null) {
      handleShow();
    }
  }, []);

  // Pedimos los datos del usuario procedentes de la base de datos
  useEffect(() => {
    userAPI
      .get(`/user/oneUser`, {headers: {authorization: token}})
      .then((res) => {
        setEditUser(res.data.resultFarmer[0]);
        if(res.data.resultFarmer[0].user_img != null){
          setProfileImg(res.data.resultFarmer[0].user_img);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  // Captamos los cambios de los input y lo intoducimos en el estado
  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditUser({ ...editUser, [name]: value });
  };

  const deletePhoto = (e) => {
    if (window.confirm('¿Seguro que desea eliminar la foto de perfil?')) {
      setEditUser({ ...editUser, user_img: null });
      setProfileImg('default.jpg');
    }
  };

  const selectPhoto = (e) => {
    e.target.children[0].click();
  };

  const uploadPhoto = (e) => {
    setProfileImg('loading.gif');
    const formData = new FormData();
    formData.append("file", e.target.files[0]);

    userAPI
      .post(`/user/uploadImg`, formData, {headers: {authorization: token}})
      .then((res) => {
        setProfileImg(res.data.filename);
        setEditUser({ ...editUser, user_img: res.data.filename });
      })
      .catch((error) => {
        editUser.user_img != null
          ? setProfileImg(editUser.user_img)
          : setProfileImg('default.jpg');
        alert("Error al subir el archivo.")
        console.log(error);
      });
  };

  // Captamos la imagen y la introducimos en el estado
  const handleFile = (e) => {
    setFile(e.target.files[0]);
  };

  const handlePassword = (e) => {
    const { value, name } = e.target;
    setPassword1(value);
  }

  const handleChangePass = () => {
    if (document.querySelector('#password2').value == password1)
      setPassMatch(true);
    else
      setPassMatch(false);
  };

  // Creamos la funcion del onClick y hacemos un axios para setear los cambios.
  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      editUser.name === '' || editUser.name === null ||
      editUser.lastname === '' || editUser.lastname === null ||
      editUser.phone === '' || editUser.phone === null ||
      editUser.email === '' || editUser.email === null
    ){
      window.alert('Complete los campos obligatorios');
      return;
    }
    if (password1){
      if (password1 === document.querySelector('#password2').value){
        editUser.password = password1;
      }else{
        window.alert('Las contraseñas no coinciden');
        return;
      }
    }else{
      editUser.password = '';
    }

    userAPI
      .post(`/user/editUser`, {edit: JSON.stringify(editUser)}, {headers: {authorization: token}})
      .then((res) => {
        setProfileChange(!profileChange);
        navigate(`/`);
      })
      .catch((error) => {
        console.log(error);
      });

  };

  const viewPass = () => {
    var passInput = document.getElementById("password1");
    var passView = document.getElementById("viewpassword1");
    if (passInput.attributes.type.value === "password") {
      passInput.attributes.type.value = "text";
      passView.children.item(0).setAttribute("class", "bi bi-eye-slash clickable");
    }
    else {
      passInput.attributes.type.value = "password";
      passView.children.item(0).setAttribute("class", "bi bi-eye clickable");
    }
  };

  const viewRePass = () => {
    var passInput = document.getElementById("password2");
    var passView = document.getElementById("viewpassword2");
    if (passInput.attributes.type.value === "password") {
      passInput.attributes.type.value = "text";
      passView.children.item(0).setAttribute("class", "bi bi-eye-slash clickable");
    }
    else {
      passInput.attributes.type.value = "password";
      passView.children.item(0).setAttribute("class", "bi bi-eye clickable");
    }
  };

  return (

    <main className='main mainheight'>
      <div className='container'>
        <Modal show={passModal} className="mt-5 text-body text-center">
          <Modal.Header>
            <h3>Cambio de contraseña</h3>
          </Modal.Header>
          <Modal.Body>
            <p className="alert alert-success mb-1 mx-3">
              Ha iniciado sesión con una contraseña de recuperación.<br/>
              Cree una nueva en esta pantalla.
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button className="btn btn-theme btn-sm" onClick={handleClose}>
              Aceptar
            </Button>&nbsp;
          </Modal.Footer>
        </Modal>
        <div className='row'>
          <div className="col text-center title-margin">
            <p></p>
            <h4><span className="text-gradient-green-orange">Mis Datos</span></h4>
          </div>
        </div>

        <div>
          <h5 className='title'>Datos identificativos</h5>
          <div className="row">
            <div className="fix-6">
              <div className="profile-img-cont">
                  <div className="d-inline-block">
                    <img className="profile-img-edit" src={`${config.MEDIA_URL}/images/profile/${profileImg}`} />
                  </div>
                  <div className="d-inline-block text-end align-middle">
                    <i className="bi bi-file-earmark-arrow-up-fill fs-2 ms-2 clickable text-success" title="Subir foto" onClick={selectPhoto}>
                      <input type="file" name="photo" id="photo" accept="image/*" onChange={uploadPhoto} hidden />
                    </i>
                    <i className="bi bi-file-earmark-x-fill fs-2 ms-2 clickable text-danger" title="Eliminar foto" onClick={deletePhoto}></i>
                  </div>
              </div>
            </div>
            <div className="col mb-4 mb-md-0 was-validated">
              <div>
                <div className="form-group mb-3 position-relative is-valid">
                  <div className="input-group input-group-lg">
                    <span className="input-group-text text-theme border-end-0"><i className="bi bi-person"></i></span>
                    <div className="form-floating">
                      <input
                        type="text"
                        name="name"
                        value={editUser.name}
                        onChange={handleChange}
                        placeholder="Nombre"
                        required
                        className="form-control border-start-0" />
                      <label>Nombre</label>
                    </div>
                  </div>
                </div>
                <div className="invalid-feedback mb-3">Añada dato válido</div>
              </div>
            </div>

            <div className="col-12 col-md-6 col-xl-4 mb-4 mb-md-0 was-validated">
              <div>
                <div className="form-group mb-3 position-relative is-valid ">
                  <div className="input-group input-group-lg">
                    <span className="input-group-text text-theme border-end-0"><i className="bi bi-people"></i></span>
                    <div className="form-floating">
                      <input
                        type="text"
                        name="lastname"
                        value={editUser.lastname}
                        onChange={handleChange}
                        placeholder="Apellido/s"
                        required
                        className="form-control border-start-0" />
                      <label>Apellido/s</label>
                    </div>
                  </div>
                </div>
                <div className="invalid-feedback mb-3">Añada dato válido</div>
              </div>
            </div>
            <div className="col-12 col-md-6 col-xl-4 mb-4 mb-md-0">
              <div>
                <div className="form-group mb-3 position-relative is-valid ">
                  <div className="input-group input-group-lg">
                    <span className="input-group-text text-theme border-end-0"><i className="bi bi-card-text"></i></span>
                    <div className="form-floating">
                      <input
                        type="text"
                        name="dni"
                        value={editUser.dni}
                        onChange={handleChange}
                        placeholder="DNI"
                        className="form-control border-start-0" />
                      <label>DNI</label>
                    </div>
                  </div>
                </div>
                <div className="invalid-feedback mb-3">Añada dato válido</div>
              </div>
            </div>
          </div>
          <h5 className='title'>Datos de contacto</h5>
          <div className="row">
            <div className="col-12 col-md-6 col-xl-4 mb-4 mb-md-0">
              <div>
                <div className="form-group mb-3 position-relative is-valid ">
                  <div className="input-group input-group-lg">
                    <span className="input-group-text text-theme border-end-0"><i className="bi bi-card-text"></i></span>
                    <div className="form-floating">
                      <input
                        type="text"
                        name="address"
                        value={editUser.address}
                        onChange={handleChange}
                        placeholder="Dirección"
                        className="form-control border-start-0" />
                      <label>Dirección</label>
                    </div>
                  </div>
                </div>
                <div className="invalid-feedback mb-3">Añada dato válido</div>
              </div>
            </div>
            <div className="col-12 col-md-6 col-xl-4 mb-4 mb-md-0  was-validated">
              <div>
                <div className="form-group mb-3 position-relative is-valid ">
                  <div className="input-group input-group-lg">
                    <span className="input-group-text text-theme border-end-0"><i className="bi bi-phone"></i></span>
                    <div className="form-floating">
                      <input
                        type="text"
                        name="phone"
                        value={editUser.phone}
                        onChange={handleChange}
                        placeholder="Teléfono"
                        required
                        className="form-control border-start-0" />
                      <label>Teléfono</label>
                    </div>
                  </div>
                </div>
                <div className="invalid-feedback mb-3">Añada dato válido</div>
              </div>
            </div>
              <div className="col-12 col-md-6 col-xl-4 mb-4 mb-md-0">
                <div>
                  <div className="form-group mb-2 position-relative">
                    <div className="input-group input-group-lg">
                      <span className="input-group-text text-theme border-end-0"><i className="bi bi-file-earmark-check"></i></span>
                      <div className="form-floating">
                        <select
                          className="form-select border-0 border-start-0"
                          name="privacity"
                          value={editUser.privacity}
                          onChange={handleChange}
                          title="privacity" >
                          <option value="0">Público</option>
                          <option value="1">Privado</option>
                        </select>
                        <label>Visibilidad del perfil</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
          </div>

          <h5 className='title'>Login</h5>
          <div className="row">
            <div className="col-12 col-md-6 col-xl-4 mb-4 mb-md-0  was-validated">
              <div>
                <div className="form-group mb-3 position-relative is-valid ">
                  <div className="input-group input-group-lg">
                    <span className="input-group-text text-theme border-end-0"><i className="bi bi-envelope"></i></span>
                    <div className="form-floating">
                      {user.confirmed == 0 && 
                        <span className="position-absolute badge rounded-pill bg-danger top-0 end-0" role="alert">No confirmado</span>
                      }
                      <input
                        type="email"
                        name="email"
                        value={editUser.email}
                        placeholder="E-mail"
                        required
                        disabled
                        className="form-control border-start-0" />
                      <label>E-mail
                      
                      </label>
                    </div>
                  </div>
                </div>
                <div className="invalid-feedback mb-3">Añada dato válido</div>
              </div>
            </div>
            <div className="col-12 col-md-6 col-xl-4 mb-0 mb-md-0  was-validated">
              <div className="form-group mb-2 position-relative check-valid empty-neutral-color">
                <div className="input-group input-group-lg">
                  <span className="input-group-text text-theme border-end-0"><i className="bi bi-key"></i></span>
                  <div className="form-floating">
                    <input
                      id="password1"
                      type="password"
                      placeholder="Introduce tu contraseña"
                      autoComplete="new-password"
                      name="password"                      
                      defaultValue={password1}
                      onChange={handlePassword}
                      className="form-control border-start-0"
                    />
                    <label htmlFor="password1">Contraseña</label>
                  </div>
                  <span className="input-group-text text-secondary  border-end-0" id="viewpassword1" onClick={viewPass}><i className="bi bi-eye clickable"></i></span>
                </div>
              </div>
              <div className="invalid-feedback mb-3 invisible"></div>
            </div>

            <div className="col-12 col-md-6 col-xl-4 mb-4 mb-md-0  was-validated">
              <div className="form-group mb-2 position-relative check-valid empty-neutral-color">
                <div className="input-group input-group-lg">
                  {passMatch && <span className="input-group-text text-theme border-end-0 text-success"><i className="bi bi-unlock"></i></span>}
                  {!passMatch && <span className="input-group-text text-theme border-end-0 text-danger"><i className="bi bi-lock"></i></span>}
                  <div className="form-floating">
                    <input
                      type="password"
                      placeholder="Re-introduzca contraseña"
                      name="password2"
                      className="form-control border-start-0"
                      id="password2" 
                      autoComplete="new-password"
                      onChange={handleChangePass}
                      />
                    <label htmlFor="password2">Confirme Contraseña</label>
                  </div>
                  <span className="input-group-text text-secondary border-end-0" id="viewpassword2" onClick={viewRePass}><i className="bi bi-eye clickable"></i></span>
                </div>
              </div>
              <div className="invalid-feedback invisible"></div>
              <div className="valid-feedback">Bien! Las contraseñas coinciden </div>
            </div>
          </div>
        

        <div className='row accept-cancel'>
          <div className="col-auto mb-2">
            <div>
              <button className='btn btn-theme'
                onClick={handleSubmit}
                type="submit">Guardar</button>
            </div>
          </div>
          <div className="col-auto mb-2">
            <div>
              <button className='btn btn-danger botonCancCreateGreen'
                type='onSubmit'
                onClick={() => navigate(`/`)}
              >Cancelar</button><br />
            </div>
          </div>
        </div>
      </div>
    </div>
    </main >
  );
};