import { userAPI, greenhouseAPI } from '../../api';
import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { GreenComp } from "../../components/GreenComp";
import { OtherGreenComp } from '../../components/OtherGreenComp';
import Cookies from 'js-cookie';
import './userFarmer.scss'

export const UserFarmer = () => {
  const [greenhouses, setGreenhouses] = useState([]);
  const [otherGreenhouses, setOtherGreenhouses] = useState([]);
  const [recargar, setRecargar] = useState(false);
  const [busqueda, setBusqueda] = useState("");
  const token = Cookies.get("token");
  
  useEffect(() => {
    greenhouseAPI
      .get(`/greenhouse/showAllGreenhouse`, {headers: {authorization: token}})
      .then((res) => {
        setGreenhouses(res.data.result);
      })
      .catch((error) => {
        console.log('ERROR: showAllGreenhouse');
      });
  }, [recargar]);

  useEffect(() => {
    greenhouseAPI
      .get(`/greenhouse/showOthersGreenhouse`, {headers: {authorization: token}})
      .then((res) => {
        setOtherGreenhouses(res.data.result);
      })
      .catch((error) => {
        console.log('ERROR: showOthersGreenhouse');
      });
  }, [recargar]);

  return (
    <main className='main mainheight'>
      <div className='container'>
        <Container className="pb-3 divTablet">
          <div className='row mb-4 keep-up'>
            <div className="col">
              <div className="card border-0 h-100">
                <div className="card-body">
                  <div className="input-group input-group-md rounded mb-2">
                    <span className="input-group-text text-theme"><i className="bi bi-search"></i></span>
                    <input className="form-control"
                      type="search"
                      placeholder="Filtrar"
                      onChange={(e) => setBusqueda(e.target.value)} />
                  </div>
                </div>
              </div>
            </div>
          </div>

          {greenhouses.length == 0 && otherGreenhouses.length == 0 &&
            <div className='no-news'>
              <i className='bi bi-bug text-secondary fs-1'></i>
              <p className='text-secondary fs-4'>No tienes invernaderos para supervisar</p>
            </div>
          }

          {greenhouses.length > 0 &&
            <>
            <div className='row'>
              <div className="col text-center title-margin">
                <h4><span className="text-gradient-green-orange">Mis Invernaderos</span></h4>
              </div>
            </div>
            <GreenComp greenhouses={greenhouses} busqueda={busqueda}/>
            </>
          }

          {otherGreenhouses.length > 0 &&
            <>
            <div className='row'>
              <div className="col text-center title-margin">
                <h4><span className="text-gradient-green-orange">Invernaderos Autorizados</span></h4>
              </div>
            </div>
            <OtherGreenComp greenhouses={otherGreenhouses} busqueda={busqueda}/>
            </>
          }

        </Container>

      </div>
    </main>);
};
