import React, { useState, useEffect } from "react";
import jwtDecode from "jwt-decode";
import { GreenInfoComp } from "../../components/GreenInfoComp";
import { Row, Col, Modal, Button } from "react-bootstrap";
import { useNavigate, useParams, Link } from "react-router-dom";
import { greenhouseAPI, userAPI } from '../../api';
import { WeatherAPI } from "../../components/weatherAPI/WeatherAPI";
import Cookies from 'js-cookie';
import "./oneGreen.scss";
import { CompPlagueAction } from "../../components/CompPlagueAction";
import vega11 from "../../assets/img/Vega11.png";
import config from "../../config.json";

export const OtherGreenhouse = () => {
  const [green, setGreen] = useState(null);
  const [dataSets, setDataSets] = useState(null);
  const [greenhouses, setGreenHouses] = useState();
  const { greenhouse_id } = useParams();
  const [load, setLoad] = useState(true);
  const [greenhouseModal, setGreenhouseModal] = useState(false);
  const [greenhouseModalMap, setGreenhouseModalMap] = useState('status_img');
  const [crops, setCrop] = useState();
  const [showCampaignChange, setCampaignChange] = useState(false);
  const [showPlagueAction, setShowPlagueAction] = useState(false);

  const [masInfoInter, setMasInfoInter] = useState(false);
  const [masInfoExt, setMasInfoExt] = useState(false);

  const navigate = useNavigate();

  const [show, setShow] = useState(false);
  const token = Cookies.get("token");

  // useEffect(() => {
  //   handleResize();
  //   window.addEventListener("resize", handleResize);
  // }, []);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [user_id, setUser_id] = useState();
  useEffect(() => {
    if (token) {
      setUser_id(jwtDecode(token).user.id);
    }
  }, [token]);

  useEffect(() => {
    greenhouseAPI
      .get(
        `/greenhouse/showAnotherGreenhouse/${greenhouse_id}`,
        {headers: {authorization: token}}
      )
      .then((res) => {
        setGreen(res.data.result[0]);
        setGreenHouses([res.data.result[0],]);
      })
      .catch((error) => {
        console.log("ERROR: showAnotherGreenhouse");
      });
  }, [load]);

  useEffect(() => {
    if (green) {
      greenhouseAPI
        .get(`/greenhouse/showLastMeasurements/${green.season_id}`, {headers: {authorization: token}})
        .then((res) => {
          if (res.data.result) {
            setDataSets(res.data.result);
          }
        })
        .catch((error) => {
          console.log("ERROR CARGANDO LECTURAS");
        });
    }
  }, [green]);

  //--------------traemos todos los tipos de cultivo
  useEffect(() => {
    greenhouseAPI
      .get(`/greenhouse/showAllCrops`)
      .then((res) => {
        if (res.data) {
          setCrop(res.data);
        }
      })
      .catch((err) => console.log(err));
  }, []);

  function showModal(evt) {
    let ghid = evt.currentTarget.dataset.greenhouseid;

    greenhouseAPI
      .get(`/greenhouse/showAnotherGreenhouse/${ghid}`, {headers: {authorization: token}})
      .then((res) => {
        res.data.result[0].currentMap = res.data.result[0].status_img;
        setGreenhouseModal(res.data.result[0]);
        const newDate = new Date(res.data.result[0].run_time);
        // setLocalDate(newDate.toLocaleString());
        setGreenhouseModalMap('status_img');
        handleShow();
      })
      .catch((error) => {
        console.log("ERROR: showAnotherGreenhouse");
      });
  }

  function changeDataset(evt) {
    setGreenhouseModal({...greenhouseModal, ...dataSets[evt.currentTarget.value]});
    const newDate = new Date(dataSets[evt.currentTarget.value].run_time);
    // setLocalDate(newDate.toLocaleString());
    // setGreenhouseModalMap('status_img');
  }

  function changeMap(evt) {
    if (evt.currentTarget.dataset.map) {
      setGreenhouseModalMap(evt.currentTarget.dataset.map);
    }
  }

  function toggleCampaignChange() {
    if (showCampaignChange) {
      setCampaignChange(false);
    } else {
      setCampaignChange(true);
    }
  }

  function Capitalize(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  const handleChangeCrop = (e) => {
    const { name, value } = e.target;
    setGreen({ ...green, ['crop_id']: value });

  }

  const handleSubmit = (e) => {
    e.preventDefault();
    toggleCampaignChange();
    if (green.production != green.crop_id){
      green.production = green.crop_id;
    }
    if (green.irrigation_system == 'null'){green.irrigation_system = ''}
    if (green.fertilize_system == 'null'){green.fertilize_system = ''}
    if (green.fertilize_type == 'null'){green.fertilize_type = ''}
    if (green.light_system == 'null'){green.light_system = ''}
    if (green.phytosan_system == 'null'){green.phytosan_system = ''}
    if (green.seed_brand == 'null'){green.seed_brand = ''}
    if (green.seed_lot == 'null'){green.seed_lot = ''}
    if (green.windows == 'null'){green.windows = ''}
    if (green.lat == null || green.lon == null)
      green['location'] = "";
    else
      green['location'] = green.lat + ',' + green.lon;

    const regGreenhouse = (({
      greenhouse_id,
      user_farmer_id,
      name_greenhouse,
      year,
      production,
      temperature,
      location,
      co2,
      crop_id,
      humidity_soil,
      humidity_air,
      quality_soil,
      irrigation_system,
      light_system,
      fertilize_type,
      fertilize_system,
      phytosan_system,
      seed_brand,
      seed_lot,
      user_engineer_id,
      health,
      windows
    }) => 
      ({
        greenhouse_id,
        user_farmer_id,
        name_greenhouse,
        year,
        production,
        temperature,
        location,
        co2,
        crop_id,
        humidity_soil,
        humidity_air,
        quality_soil,
        irrigation_system,
        light_system,
        fertilize_type,
        fertilize_system,
        phytosan_system,
        seed_brand,
        seed_lot,
        user_engineer_id,
        health,
        windows})
        )(green);
    console.log('SAVING GREENHOUSE');
    greenhouseAPI
        .post(`/greenhouse/editGreenhouse/${regGreenhouse.greenhouse_id}`, regGreenhouse, {headers: {authorization: token}})
        .then((res) => {
          navigate(`/userFarmer`);
        })
        .catch((error) => {
          console.log("ERROR: editGreenhouse");
        });
  }


  return (

    <main className='main mainheight'>
      <div className='container'>
        <Row>
          <Col>
            {greenhouses && greenhouses.length > 0 ? (
              <Modal show={show} onHide={handleClose}>

                <Modal.Header><div className="row">
                  <div className='col-12'>
                    {greenhouseModal && (
                      <><h4>{greenhouseModal.name_greenhouse}</h4>
                        <label className="text-secondary me-2">Análisis: </label>
                        <select id="datasets" onChange={changeDataset} className="form-select-sm">
                          {dataSets && 
                            dataSets.map((data, index) =>{
                              return <option key={index} value={index} id={index}>{(new Date(data.run_time)).toLocaleString().slice(0, -3)}</option>
                            })
                          }
                        </select></>
                    )}
                  </div>
                </div>
                </Modal.Header>

                <Modal.Header>
                  <div className="container">
                  <div className="row gx-2">
                    {greenhouseModal.status_img && (
                      <div className='col'>
                        <button className='btn btn-theme btn-sm w-100' onClick={changeMap} data-map='status_img'>
                          <i className='bi bi-thermometer-half fs-4 d-block'></i>TEM
                        </button>
                      </div>)
                    }
                    {greenhouseModal.diseases_img && (
                      <div className='col'>
                        <button className='btn btn-theme btn-sm w-100' onClick={changeMap} data-map='diseases_img' translate="no">
                          <i className='bi bi-moisture fs-4 d-block'></i>HUM
                        </button>
                      </div>)
                    }
                    {greenhouseModal.co2_img && (
                      <div className='col'>
                        <button className='btn btn-theme btn-sm w-100' onClick={changeMap} data-map='co2_img'>
                          <i className='bi bi-balloon fs-4 d-block'></i>CO2
                        </button>
                      </div>)
                    }
                    {greenhouseModal.plagues_img && (
                      <div className='col'>
                        <button className='btn btn-theme btn-sm w-100' onClick={changeMap} data-map='plagues_img'>
                          <i className='bi bi-radioactive fs-4 d-block'></i>ENF
                        </button>
                      </div>)
                    }
                    {greenhouseModal.growth_img && (
                      <div className='col'>
                        <button className='btn btn-theme btn-sm w-100' onClick={changeMap} data-map='growth_img'>
                          <i className='bi bi-sort-up fs-4 d-block'></i>CRE
                        </button>
                      </div>)
                    }
                    {greenhouseModal.count_img && (
                      <div className='col'>
                        <button className='btn btn-theme btn-sm w-100' onClick={changeMap} data-map='count_img'>
                          <i className='bi bi-basket fs-4 d-block'></i>REC
                        </button>
                      </div>
                    )
                    }
                  </div>
                  </div>
                </Modal.Header>

                <Modal.Body>
                  {greenhouseModal[greenhouseModalMap] && (
                    <img
                      src={`data:image/png;base64,${btoa(String.fromCharCode(...new Uint8Array(greenhouseModal[greenhouseModalMap].data)))}`}
                      className="imgModal"
                    />
                  )}
                  {/* <Plot
                    data={ [ data_z1, data_z2, data_z3 ]}
                    layout={{
                      width: plotW,
                      height: plotH,
                      l: 0, r: 0, b: 0, t: 0, pad: 0,
                      title: 'Basic Plot'
                    }}
                    config={{scrollZoom: true, displaylogo: false}}
                  /> */}
                </Modal.Body>

                <Modal.Footer>
                  <Button className="btn btn-theme btn-sm" onClick={handleClose}>
                    Cerrar
                  </Button>&nbsp;
                </Modal.Footer>

              </Modal>
            ) : null}
            {/* --------------- FIN DEL MODAL------------------- */}
          </Col>
        </Row>

        <div className='row'>
          <div className="col text-center title-margin">
            <h4><span className="text-gradient-green-orange">Invernadero asignado</span></h4>
          </div>
        </div>

        {green ? (
          <div>
            <div className='row'>
              <div className='col-md-6 col-12'>
                <div className="card bg-white border-0 z-index-1">
                  <div className="card-body">
                    <div className='row'>
                      <div className='col'>
                        <h2>{green.name_greenhouse}</h2><br />
                      </div>
                    </div>
                    
                    <div className='row' >
                    <div className='col-auto'>
                    <img src={vega11} style={{maxHeight:'35px', marginTop: '6px', marginBottom: '4px'}} />
                    </div>
                      <div className='col-auto'>
                      {green.risks &&
                          <button
                            onClick={() => setShowPlagueAction(!showPlagueAction)}
                            className="btn btn-danger btn-sm me-2"
                          >
                            <i className="bi bi-bug text-white pe-1"></i>
                            <span className="text-white">En riesgo</span>
                          </button>
                      }
                      {green.status_img &&
                          <button
                            onClick={showModal}
                            className='btn btn-success btn-sm'
                            data-greenhouseid={green.greenhouse_id}
                          >
                            <i className="bi bi-activity text-white pe-1"></i>
                            <span className="text-white">Estado</span>
                          </button>
                      }
                      {!green.status_img && !green.risks &&
                          <p className="alert alert-secondary p-2">
                            No hay datos disponibles
                          </p>
                      }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <br />
            <div>
              {showPlagueAction && (
                <CompPlagueAction
                  risks={green.risks}
                />
              )}
              <br />
            </div>
            <div className='row'>
              <div className='col-md-6 col-12'>
                <div className="card bg-white border-0 z-index-1">
                  <div className="card-body">
                    <GreenInfoComp
                      masInfoExt={masInfoExt}
                      masInfoInter={masInfoInter}
                      setMasInfoInter={setMasInfoInter}
                      green={green}
                      setgreen={setGreen}
                      setLoad={setLoad}
                      load={load}
                    />
                  </div>
                </div>
              </div>
            </div>
            <br />
            <div className='row'>
              <div className='col-md-6 col-12'>
                <div className="card bg-white border-0 z-index-1">
                  <div className="card-body">
                    <h5 className="fw-medium mb-0">Condiciones externas</h5>
                    <hr />
                    <WeatherAPI
                      masInfoExt={masInfoExt}
                      masInfoInter={masInfoInter}
                      setMasInfoExt={setMasInfoExt}
                      greenhouse={green}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

        ) : null}
        
        <br />

      </div>
    </main>
  );
};
