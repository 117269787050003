import React, { useEffect, useState } from "react";
import { Navbar, Container, Nav, Button } from "react-bootstrap";
import { Link, useNavigate, redirect } from "react-router-dom";
import jwtDecode from "jwt-decode";
import Cookies from 'js-cookie';
//import "../assets/scss/_sidebar.scss";

export const SideBar = ({ isLogged, setIsLogged, user, token, notif, mediaURL, messg, setModalPost }) => {
    const [userId, setUserId] = useState();
    const [userType, setUserType] = useState();
    const navigate = useNavigate();

    // const logOut = () => {
    //     Cookies.remove("token");
    //     setIsLogged(false);
    //     navigate("/login");
    // };
    // const typeClass = user.type == 0 ? 'farmer-img' : user.type == 1 ? 'engin-img' : 'admin-img';

    useEffect(() => {
        if (token) {
            setUserId(jwtDecode(token).user.id);
            setUserType(jwtDecode(token).user.type);
        }
    }, [isLogged, token]);


    function refreshsidebar() {
        /* url path on menu */
        //console.log('refreshing sidebar ---------------------------------------------');
        var path = window.location.pathname;
        var links_menu = document.querySelectorAll('.sidebar-wrap .nav li a');

        links_menu.forEach(element => {
            //console.log(element + ' remove active');
            element.classList.remove('active');
            if (element.getAttribute('href') == path) {
                element.classList.add("active");
                //console.log(element + ' add active');
                if (element.closest('.dropdown')) {
                    //console.log('dropdown add show');
                    element.closest('.dropdown').children[0].classList.add('show'); //dropdown-toggle
                    element.closest('.dropdown').children[1].classList.add('show'); //dropdown-menu
                }
            } else {
                //console.log('deactivating:'+ element);
                element.classList.remove("active");
                if (element.closest('.dropdown')) {
                    if (!element.closest('.dropdown').children[0].classList.contains('active')) {
                        //console.log('deactivating dropdown');
                        element.closest('.dropdown').children[0].classList.remove('show'); //dropdown-toggle
                        element.closest('.dropdown').children[1].classList.remove('show'); //dropdown-menu
                    }
                }
            }

        });
    }

    refreshsidebar();
    
    return (
        <>
            {isLogged && user ? (
                <div className="sidebar-wrap">
                    <div className={`sidebar`}>
                        <div className="container">
                            {isLogged && (
                            <Link className="" to={`/editUser`} title="Editar usuario">
                            <div className="row mb-3 mt-1">
                                <div className="col-auto position-relative">
                                    {!user.user_img && <img className={`profile-img`} src={`${mediaURL}/images/profile/default.jpg`} />}
                                    {user.user_img && <img className={`profile-img`} src={`${mediaURL}/images/profile/${user.user_img}`} />}
                                    {user.privacity==1 && <span title="Perfil privado" className="profile-lock"><i className="bi bi-lock-fill"></i></span>}
                                </div>
                                <div className="col align-self-center ps-1 text-truncate">
                                    <h5 className="mb-0">
                                        { user.name }
                                        { user.type == "2" && <span><i className="bi bi-gear-fill ba-xl fs-6 text-danger ms-1"></i></span> }
                                    </h5>
                                    <span>{ user.lastname }</span>
                                </div>
                            </div>
                            </Link>
                            )} 
                            <div className="row mb-4">
                                <div className="col-12 px-0">
                                    <ul className="nav nav-pills">
                                        {isLogged && (
                                            <>
                                                <li className="nav-item dropdown">
                                                    <Nav.Link
                                                        className="dropdown-toggle" data-bs-toggle="dropdown" data-bs-auto-close="false" data-bs-display="static" role="button" aria-expanded="false"
                                                        as={Link}
                                                        to={`/userFarmer`}
                                                    ><div className="avatar avatar-40 icon"><i className="bi bi-flower1"></i></div>
                                                        <div className="col">Invernaderos</div>
                                                        <div className="arrow">
                                                            <i className="bi bi-chevron-down plus"></i><i className="bi bi-chevron-up minus"></i>
                                                        </div>
                                                    </Nav.Link>
                                                    <ul className="dropdown-menu">
                                                        <li>
                                                            <Nav.Link className="dropdown-item nav-link" as={Link} to={"/createGreenhouse"}>
                                                                <div className="avatar avatar-40 icon"><i className="bi bi-patch-plus"></i>
                                                                </div>
                                                                <div className="col align-self-center">Crear Invernadero</div>
                                                                <div className="arrow"><i className="bi bi-chevron-right"></i></div>
                                                            </Nav.Link>
                                                        </li>
                                                    </ul>
                                                </li>
                                            </>
                                        )}
{/* 
                                        {isLogged && userType === 1 && (

                                            <li className="nav-item">
                                                <Nav.Link
                                                    className=""
                                                    as={Link}
                                                    to={`/oneEngineer2`}
                                                >
                                                    <div className="avatar avatar-40 icon"><i className="bi bi-flower1"></i></div>
                                                    <div className="col">Mis Invernaderos</div>
                                                </Nav.Link>
                                            </li>
                                        )}

                                        {isLogged && userType === 2 && (
                                            <>
                                                <li className="nav-item">
                                                    <Nav.Link
                                                        className=""
                                                        as={Link}
                                                        to="/admin"
                                                    >
                                                        <div className="avatar avatar-40 icon"><i className="bi bi-house-door"></i></div>
                                                        <div className="col">Inicio</div>
                                                        <div className="arrow"><i className="bi bi-chevron-right"></i></div>
                                                    </Nav.Link>
                                                </li>
                                                <li className="nav-item dropdown">
                                                    <Nav.Link
                                                        className="dropdown-toggle" data-bs-toggle="dropdown" data-bs-auto-close="false" data-bs-display="static" role="button" aria-expanded="false"
                                                        as={Link}
                                                        to="/admin/allFarmers"
                                                    ><div className="avatar avatar-40 icon"><i className="bi bi-bucket"></i></div>
                                                        <div className="col">Agricultores</div>
                                                        <div className="arrow">
                                                            <i className="bi bi-chevron-down plus"></i><i className="bi bi-chevron-up minus"></i>
                                                        </div>
                                                    </Nav.Link>
                                                    <ul className="dropdown-menu">
                                                        <li>
                                                            <Nav.Link className="dropdown-item nav-link" as={Link} to="/admin/adminCreateFarmer">
                                                                <div className="avatar avatar-40 icon"><i className="bi bi-person-plus"></i>
                                                                </div>
                                                                <div className="col align-self-center">Crear agricultor</div>
                                                                <div className="arrow"><i className="bi bi-chevron-right"></i></div>
                                                            </Nav.Link>
                                                        </li>
                                                    </ul>
                                                </li>

                                                <li className="nav-item dropdown">
                                                    <Nav.Link
                                                        className="dropdown-toggle" data-bs-toggle="dropdown" data-bs-auto-close="false" data-bs-display="static" role="button" aria-expanded="false"
                                                        as={Link}
                                                        to="/admin/allEngineers"
                                                    ><div className="avatar avatar-40 icon"><i className="bi bi-wrench-adjustable-circle"></i></div>
                                                        <div className="col">Ingenieros</div>
                                                        <div className="arrow">
                                                            <i className="bi bi-chevron-down plus"></i><i className="bi bi-chevron-up minus"></i>
                                                        </div>
                                                    </Nav.Link>
                                                    <ul className="dropdown-menu">
                                                        <li>
                                                            <Nav.Link className="dropdown-item nav-link" as={Link} to="/createEngineer">
                                                                <div className="avatar avatar-40 icon"><i className="bi bi-person-plus"></i>
                                                                </div>
                                                                <div className="col align-self-center">Crear Ingeniero</div>
                                                                <div className="arrow"><i className="bi bi-chevron-right"></i></div>
                                                            </Nav.Link>
                                                        </li>
                                                    </ul>
                                                </li>

                                                <li className="nav-item dropdown">
                                                    <Nav.Link
                                                        className="dropdown-toggle" data-bs-toggle="dropdown" data-bs-auto-close="false" data-bs-display="static" role="button" aria-expanded="false"
                                                        as={Link}
                                                        to="/admin/AdminAllGreenhouse"
                                                    ><div className="avatar avatar-40 icon"><i className="bi bi-flower1"></i></div>
                                                        <div className="col">Invernaderos</div>
                                                        <div className="arrow">
                                                            <i className="bi bi-chevron-down plus"></i><i className="bi bi-chevron-up minus"></i>
                                                        </div>
                                                    </Nav.Link>
                                                    <ul className="dropdown-menu">
                                                        <li>
                                                            <Nav.Link className="dropdown-item nav-link" as={Link} to="/admin/createGreenhouse">
                                                                <div className="avatar avatar-40 icon"><i className="bi bi-patch-plus"></i>
                                                                </div>
                                                                <div className="col align-self-center">Crear Invernadero</div>
                                                                <div className="arrow"><i className="bi bi-chevron-right"></i></div>
                                                            </Nav.Link>
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li className="nav-item dropdown">
                                                    <Nav.Link
                                                        className="dropdown-toggle" data-bs-toggle="dropdown" data-bs-auto-close="false" data-bs-display="static" role="button" aria-expanded="false"
                                                        as={Link}
                                                        to="/admin/plagues"
                                                    ><div className="avatar avatar-40 icon"><i className="bi bi-bug"></i></div>
                                                        <div className="col">Plagas</div>
                                                        <div className="arrow">
                                                            <i className="bi bi-chevron-down plus"></i><i className="bi bi-chevron-up minus"></i>
                                                        </div>
                                                    </Nav.Link>
                                                    <ul className="dropdown-menu">
                                                        <li>
                                                            <Nav.Link className="dropdown-item nav-link" as={Link} to="/admin/createPlague">
                                                                <div className="avatar avatar-40 icon"><i className="bi bi-patch-plus"></i>
                                                                </div>
                                                                <div className="col align-self-center">Crear Plaga</div>
                                                                <div className="arrow"><i className="bi bi-chevron-right"></i></div>
                                                            </Nav.Link>
                                                        </li>
                                                    </ul>
                                                </li>
                                            </>
                                        )} */}

                                        {isLogged && (
                                            <>
                                            <li className="nav-item dropdown">
                                                <Nav.Link
                                                    className="dropdown-toggle" data-bs-toggle="dropdown" data-bs-auto-close="false" data-bs-display="static" role="button" aria-expanded="false"
                                                    as={Link}
                                                    to={`/allContacts`}
                                                ><div className="avatar avatar-40 icon"><i className="bi bi-people"></i></div>
                                                    <div className="col">Mis Contactos</div>
                                                    <div className="arrow">
                                                        <i className="bi bi-chevron-down plus"></i><i className="bi bi-chevron-up minus"></i>
                                                    </div>
                                                </Nav.Link>
                                                <ul className="dropdown-menu">
                                                    <li>
                                                        <Nav.Link className="dropdown-item nav-link" as={Link} to={"/searchContacts"}>
                                                            <div className="avatar avatar-40 icon"><i className="bi bi-person-plus"></i>
                                                            </div>
                                                            <div className="col align-self-center">Añadir Contacto</div>
                                                            <div className="arrow"><i className="bi bi-chevron-right"></i></div>
                                                        </Nav.Link>
                                                    </li>
                                                </ul>
                                            </li>
                                            <hr className="m-0 mb-1 p-0 sep-color"/>
                                            <li className="nav-item dropdown">
                                                <Nav.Link
                                                    className="dropdown-toggle" data-bs-toggle="dropdown" data-bs-auto-close="false" data-bs-display="static" role="button" aria-expanded="false"
                                                    as={Link}
                                                    to={`/board`}
                                                ><div className="avatar avatar-40 icon"><i className="bi bi-newspaper"></i></div>
                                                    <div className="col">Publicaciones</div>
                                                    <div className="arrow">
                                                        <i className="bi bi-chevron-down plus"></i><i className="bi bi-chevron-up minus"></i>
                                                    </div>
                                                </Nav.Link>
                                                <ul className="dropdown-menu">
                                                    <li>
                                                        <div className="dropdown-item nav-link clickable" onClick={()=>setModalPost(true)}>
                                                            <div className="avatar avatar-40 icon"><i className="bi bi-file-plus"></i>
                                                            </div>
                                                            <div className="col align-self-center">Nueva publicación</div>
                                                            <div className="arrow"><i className="bi bi-chevron-right"></i></div>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </li>
                                            <li className="nav-item">
                                                <Nav.Link as={Link} to={"/chatlist"}>
                                                    <div className="avatar avatar-40 icon"><i className="bi bi-chat-text"></i></div>
                                                    <span>Mensajes
                                                    {messg > 0 && <span className="badge bg-danger rounded-pill ms-2">{messg}</span>}
                                                    </span>
                                                </Nav.Link>
                                            </li>
                                            <li className="nav-item">
                                                <Nav.Link as={Link} to={"/notifications"}>
                                                    <div className="avatar avatar-40 icon"><i className="bi bi-bell"></i></div>
                                                    <span>Notificaciones
                                                    {notif > 0 && <span className="badge bg-danger rounded-pill ms-2">{notif}</span>}
                                                    </span>
                                                </Nav.Link>
                                            </li>
                                            </>
                                        )}
                                        <hr className="m-0 mb-1 p-0 sep-color"/>
                                        { isLogged && (
                                            <li className="nav-item">
                                                <Nav.Link as={Link} to={"/logout"}>
                                                    <div className="avatar avatar-40 icon"><i className="bi bi-box-arrow-left"></i></div>
                                                    Salir
                                                </Nav.Link>
                                            </li>
                                        )}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : null}
        </>
    )
}