import { greenhouseAPI } from '../api';
import React from "react";
import { Col } from "react-bootstrap";
import Cookies from 'js-cookie';
import "./greenInfoComp.scss";

export const GreenInfoComp = ({ green, getGreen, load, setLoad, masInfoInter, masInfoExt, setMasInfoInter, role_id }) => {
  const token = Cookies.get("token");

  function Capitalize(str){
    if (str==null){return null;}
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  //CAMBIA EL ESTADO DE INFECTADO A "EN TRATAMIENTO"
  const handleTreatment = () => {
    const data = green.role_id ? {role_id: green.role_id} : {};

    greenhouseAPI
      .post(
        `/greenhouse/editInfectedTreatment/${green.greenhouse_id}`, data, {headers: {authorization: token}}
      )
      .then((res) => {
        setLoad(!load);
      })
      .catch((error) => {
        console.log('ERROR DE CAMBIO A EN TRATAMIENTO');
      });
  };
  //CAMBIA EL ESTADO DE INFECTADO A "SANO"
  const handleHealthy = () => {
    const data = green.role_id ? {role_id: green.role_id} : {};

    greenhouseAPI
      .post(
        `/greenhouse/editInfectedHealthy/${green.greenhouse_id}`, data, {headers: {authorization: token}}
      )
      .then((res) => {
        setLoad(!load);
      })
      .catch((error) => {
        console.log("ERROR DE CAMBIO A SANO");
      });
  };

  //CAMBIA EL ESTADO DE INFECTADO A "INFECTADO"
  const handleInfected = () => {
    const data = green.role_id ? {role_id: green.role_id} : {};

    greenhouseAPI
      .post(
        `/greenhouse/editInfected/${green.greenhouse_id}`, data, {headers: {authorization: token}}
      )
      .then((res) => {
        setLoad(!load);
      })
      .catch((error) => {
        console.log("ERROR DE CAMBIO A INFECTADO");
      });
  };


  return (
    <div className='row'>
      <Col>
        <h5 className="fw-medium mb-0">Condiciones internas</h5>
        <hr />
        {green.role_id == 2 && (<div className="row situacion">
          <h5>Situación:</h5>
          <div className='col-4'>
            <div className="form-check form-check-circle">
              <label className="form-check-label text-secondary small" htmlFor="tratamiento">En tratam.</label>
              <input id="tratamiento" className="form-check-input" type="radio" onClick={handleTreatment} name="plaga" defaultChecked={green.infected !== 0 && green.infected !== 1}/>
            </div>
          </div>

          <div className='col-4'>
            <div className="form-check form-check-circle">
              <label className="form-check-label text-secondary small" htmlFor="curado">Sano</label>
              <input id="curado" className="form-check-input" type="radio" onClick={handleHealthy} name="plaga"  defaultChecked={green.infected === 0}/>
            </div>
          </div>

          <div className='col-4'>
            <div className="form-check form-check-circle">
            <label className="form-check-label text-secondary small" htmlFor="infectado">Infectado</label>
            <input id='infectado' className="form-check-input" type="radio" onClick={handleInfected} name="plaga" defaultChecked={green.infected === 1}/>
          </div>
          </div>
        </div>)
        }

        <br />

        <p className="text-secondary small mb-3">
          Estado:<b>{" "}
            {green.infected === 0
              ? "Sano"
              : green.infected === 1
                ? "Infectado"
                : "En Tratamiento"}</b>
        </p>
        <p className="text-secondary small mb-3">
          Cultivo:<b> {Capitalize(green.gh_crop_name)}</b>
        </p>
        <p className="text-secondary small mb-3">
          Salud:<b> {green.health}</b>
        </p>
        <p className="text-secondary small mb-3">
          Humedad del suelo:<b> {green.humidity_soil ? green.humidity_soil + '%': ''}</b>
        </p>
        <p className="text-secondary small mb-3">
          Temperatura:<b> {green.temperature ? green.temperature  + ' ºC': ''}</b>
        </p>
        <p className="text-secondary small mb-3">
          Calidad de la tierra:<b> {Capitalize(green.quality_soil)}</b>
        </p>

        {
          masInfoInter ? (
            <Col>
              <p className="text-secondary small mb-3">
                Humedad del aire:<b> {green.humidity_air ? green.humidity_air + ' ºC' : ''}</b>
              </p>
              <p className="text-secondary small mb-3">
                CO2:<b> {green.co2 ? green.co2 + ' ppm' : ''}</b>
              </p>
              <p className="text-secondary small mb-3">
                Año:<b> {green.year}</b>
              </p>
              <p className="text-secondary small mb-3">
                Sistema de riego:<b> {green.irrigation_system}</b>
              </p>
              <p className="text-secondary small mb-3">
                Sistema de luz:<b> {green.light_system}</b>
              </p>
              <p className="text-secondary small mb-3">
                Sistema de fertilización:<b> {green.fertilize_system}</b>
              </p>
              <p className="text-secondary small mb-3">
                Tipo de fertilizante:<b> {green.fertilize_type}</b>
              </p>
              <p className="text-secondary small mb-3">
                Phytosan:<b> {green.phytosan_system}</b>
              </p>
              <p className="text-secondary small mb-3">
                Marca de las semillas:<b> {green.seed_brand}</b>
              </p>
              <p className="text-secondary small mb-3">
                Lote de semillas:<b> {green.seed_lot}</b>
              </p>
              <p className="text-secondary small mb-3">
                Producción:<b> {green.production}</b>
              </p>
            </Col>
          ) : null
        }
        <button
          className="btn btn-sm"
          onClick={() => {
            setMasInfoInter(!masInfoInter);
          }}
        >
          <i
            className={
              masInfoInter
                ? "bi bi-dash-square"
                : "bi bi-plus-square"
            }
          >
          </i>
        </button>
      </Col >
    </div >
  );
};
