import {userAPI, greenhouseAPI} from '../api';
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import jwtDecode from "jwt-decode";
import Cookies from 'js-cookie';
import "./formCreateGreen.scss";

export const FormEditGreenhouse = () => {
  const { greenhouse_id } = useParams();
  const navigate = useNavigate();
  const [engineer, setEngineer] = useState();
  const [crops, setCrop] = useState();
  const farmer=null;
  const token = Cookies.get("token");

  function Capitalize(str){
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
  
  const [regGreenhouse, setEditGreenhouse] = useState({
    name_greenhouse: "",
    location: "",
    year: "",
    production: "",
    crop_id: "",
    irrigation_system: "",
    light_system: "",
    fertilize_system: "",
    phytosan_system: "",
    seed_brand: "",
    seed_lot: "",
    user_farmer_id: "",
    user_engineer_id: ""
  });

//--------------traemos todos los tipos de cultivo
useEffect(() => {
    greenhouseAPI
        .get(`/greenhouse/showAllCrops`)
        .then((res) => {
            if (res.data) {
                setCrop(res.data);
            }
        })
        .catch((err) => console.log(err));
}, []);



  //--------------traemos los datos del invernadero
  useEffect(() => {
    greenhouseAPI
      .get(
        `/greenhouse/showOneEditGreenhouse/${greenhouse_id}`, {headers: {authorization: token}}
      )
      .then((res) => {
        if (res.data.result[0].lat == null || res.data.result[0].lon == null)
            setEditGreenhouse({...res.data.result[0], location: ""});
        else
            setEditGreenhouse({ ...res.data.result[0], location: res.data.result[0].lat + ',' + res.data.result[0].lon });
      })
      .catch((error) => {
        console.log("error recibiendo datos del invernadero");
      });
  }, []);

  useEffect(() => {
    userAPI
      .get(`/user/allEngineersFromFarmer`, {headers: {authorization: token}})
      .then((res) => {
        if (res.data) {
          setEngineer(res.data.result);
        }
      })
      .catch((err) => console.log(err));
  }, [ regGreenhouse ]);

  //---------------Buscamos el tipo de usuario
  const [userType, setUserType] = useState();
  useEffect(() => {
    if (token) {
      setUserType(jwtDecode(token).user.type);
    }
  }, []);

  // No a datos mayores a 2 digitos en los campos: temp, Co2, humedad suelo, humedad aire, calidad suelo.
  const handleChange = (e) => {
    if (e.target.name === "year" && e.target.value.length > 4) {
      window.alert("EL año tiene que ser 4 digitos");
    } else {
      const { name, value } = e.target;
      setEditGreenhouse({
        ...regGreenhouse,
        [e.target.name]: e.target.value,
      });
    }
  };

  //Capturar localización
  const handleLocation = (e) => {
        navigator.geolocation.getCurrentPosition((pos)=>{
            setEditGreenhouse({ ...regGreenhouse, location: pos.coords.latitude + ',' + pos.coords.longitude });
        });
  };


  const handleLabel = (e) => {
    e.target.nextSibling.innerHTML = e.target.title;
  };

  const handleLabelOut = (e) => {
    if (e.target.value.length == 0)
      e.target.nextSibling.innerHTML = e.target.placeholder;
  };

  //Enviamos datos editados a la BBDD y volvemos a la pagina de invernaderos
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('SAVING GREENHOUSE');
    console.log(regGreenhouse)
    regGreenhouse.production = regGreenhouse.crop_id;
    if (
      regGreenhouse.name_greenhouse === "" || regGreenhouse.name_greenhouse === null ||
      regGreenhouse.year === "" || regGreenhouse.year === null ||
      regGreenhouse.user_farmer_id === "" || regGreenhouse.user_farmer_id === null
    ) {
      window.alert("Rellene campos obligatorios");
    }
  else{
      regGreenhouse.production = regGreenhouse.crop_id;
      greenhouseAPI
        .post(
          `/greenhouse/editGreenhouse/${greenhouse_id}`,
          regGreenhouse,
          {headers: {authorization: token}}
        )
        .then((res) => {
          if (userType === 2) {
            navigate(`/admin/adminGreenhouse/${regGreenhouse.greenhouse_id}`);
          } else {
            navigate(`/userFarmer`);
          }
        })
        .catch((error) => {
          console.log("error mandando nuevos datos del invernadero");
        });
    }
  };

  return (
    <main className='main mainheight'>
      <div className='container'>
        
        <div className='row'>
          <div className="col text-center title-margin">
            <h4>Editar <span className="text-gradient-green-orange">invernadero</span></h4>
          </div>
        </div>

        <h5 className='title'>Invernadero</h5>
                <div className="row">


                    <div className="col-12 col-md-6 col-xl-4 mb-4 mb-md-0  was-validated">
                        <div>
                            <div className="form-group mb-3 position-relative check-valid is-valid">
                                <div className="input-group input-group-lg">
                                    <span className="input-group-text text-theme border-end-0"><i className="bi bi-card-text"></i></span>
                                    <div className="form-floating">
                                        <input
                                            type="text"
                                            name="name_greenhouse"
                                            value={regGreenhouse.name_greenhouse}
                                            onChange={handleChange}
                                            placeholder="Nombre del invernadero"
                                            required
                                            className="form-control border-start-0" />
                                        <label>Nombre</label>
                                    </div>
                                </div>
                            </div>
                            <div className="invalid-feedback mb-3">Añada dato válido</div>
                        </div>
                    </div>

                    <div className="col-12 col-md-6 col-xl-4 mb-4 mb-md-0  no-check-valid">
                        <div>
                            <div className="form-group mb-3 position-relative check-valid is-valid">
                                <div className="input-group input-group-lg">
                                    <span className="input-group-text text-theme border-end-0"><i className="bi bi-globe"></i></span>
                                    <div className="form-floating" onFocus={handleLabel} onBlur={handleLabelOut}>
                                        <input
                                            type="text"
                                            name="location"
                                            value={regGreenhouse.location}
                                            onChange={handleChange}
                                            className="form-control border-start-0"
                                            placeholder="Ubicación: <span class='placeh'>Coordenadas (lat, lon)</span>"
                                            title="Ubicación: <span class='placeh'>latitud, longitud</span>" />
                                        <label>Ubicación: <span className='placeh'>Coordenadas (lat, lon)</span></label>
                                    </div>
                                    <button className='btn btn-theme float-end' onClick={handleLocation}>GPS</button>
                                </div>
                            </div>
                            <div className="invalid-feedback mb-3">Añada dato válido</div>
                        </div>
                    </div>

                    <div className="col-12 col-md-6 col-xl-4 mb-4 mb-md-0  was-validated">
                        <div>
                            <div className="form-group mb-3 position-relative check-valid is-valid">
                                <div className="input-group input-group-lg">
                                    <span className="input-group-text text-theme border-end-0"><i className="bi bi-calendar2"></i></span>
                                    <div className="form-floating">
                                        <input
                                            type="number"
                                            name="year"
                                            value={regGreenhouse.year}
                                            onChange={handleChange}
                                            placeholder="Año"
                                            required
                                            className="form-control border-start-0" />
                                        <label>Año</label>
                                    </div>
                                </div>
                            </div>
                            <div className="invalid-feedback mb-3">Añada dato válido</div>
                        </div>
                    </div>

                    <div className="col-12 col-md-6 col-xl-4 mb-4 mb-md-0  was-validated">
                        <div>
                            <div className="form-group mb-3 position-relative check-valid is-valid">
                                <div className="input-group input-group-lg">
                                    <span className="input-group-text text-theme border-end-0"><i className="bi bi-card-text"></i></span>
                                    <div className="form-floating">
                                        <select
                                            name="crop_id"
                                            value={regGreenhouse.crop_id}
                                            onChange={handleChange}
                                            placeholder="Tipo Cultivo"
                                            required
                                            className="form-select border-0 border-start-0" >
                                            <option value="">Seleccione...</option>

                                            {crops ? crops.map((crop, index) => {
                                            return (
                                                <option key={index} value={crop.crop_id}>{Capitalize(crop.crop_name)}</option>
                                            )
                                        }) : null}
                                        </select>
                                        <label>Cultivo/s</label>
                                    </div>
                                </div>
                            </div>
                            <div className="invalid-feedback mb-3">Añada dato válido</div>
                        </div>
                    </div>

                </div>


                <h5 className='title'>Sistemas</h5>
                <div className="row">

                    <div className="col-12 col-md-6 col-xl-4 mb-4 mb-md-0  no-was-validated">
                        <div>
                            <div className="form-group mb-3 position-relative no-check-valid ">
                                <div className="input-group input-group-lg">
                                    <span className="input-group-text text-theme border-end-0"><i className="bi bi-card-text"></i></span>
                                    <div className="form-floating">
                                        <select
                                            className="form-select border-0 border-start-0"
                                            name="irrigation_system"
                                            value={regGreenhouse.irrigation_system}
                                            onChange={handleChange}
                                            placeholder="Riego: <span class='placeh'>goteo, aspersión...</span>"
                                            title="Riego" >
                                            <option value="">Seleccione...</option>
                                            <option value="Goteo">Goteo</option>
                                            <option value="Microaspersión">Microaspersión</option>
                                            <option value="Difusores">Difusores</option>
                                            <option value="Subterráneo">Subterráneo</option>
                                            <option value="Con manguera">Con manguera</option>
                                            <option value="Hidropónico">Hidropónico</option>
                                            <option value="Nebulización">Nebulización</option>
                                        </select>
                                        <label>Riego</label>
                                    </div>
                                </div>
                            </div>
                            <div className="invalid-feedback mb-3">Añada dato válido</div>
                        </div>
                    </div>

                    <div className="col-12 col-md-6 col-xl-4 mb-4 mb-md-0  no-was-validated">
                        <div>
                            <div className="form-group mb-3 position-relative no-check-valid ">
                                <div className="input-group input-group-lg">
                                    <span className="input-group-text text-theme border-end-0"><i className="bi bi-card-text"></i></span>
                                    <div className="form-floating" onFocus={handleLabel} onBlur={handleLabelOut}>
                                      <input
                                        className="form-control border-start-0"
                                        type="text"
                                        name="light_system"
                                        value={regGreenhouse.light_system}
                                        onChange={handleChange}
                                        placeholder="Iluminación: <span class='placeh'>solar, artificial...</span>"
                                        title="Iluminación" />
                                      <label>Iluminación: <span className="placeh">solar, artificial...</span></label>
                                    </div>
                                </div>
                            </div>
                            <div className="invalid-feedback mb-3">Añada dato válido</div>
                        </div>
                    </div>

                    <div className="col-12 col-md-6 col-xl-4 mb-4 mb-md-0  no-was-validated">
                        <div>
                            <div className="form-group mb-3 position-relative no-check-valid ">
                                <div className="input-group input-group-lg">
                                    <span className="input-group-text text-theme border-end-0"><i className="bi bi-card-text"></i></span>
                                    <div className="form-floating" onFocus={handleLabel} onBlur={handleLabelOut}>
                                      <input
                                        className="form-control border-start-0"
                                        type="text"
                                        name="fertilize_system"
                                        value={regGreenhouse.fertilize_system}
                                        onChange={handleChange}
                                        placeholder="Fertilización: <span class='placeh'>fertirriego, manual...</span>"
                                        title="Fertilización" />
                                      <label>Fertilización: <span className="placeh">fertirriego, manual...</span></label>
                                    </div>
                                </div>
                            </div>
                            <div className="invalid-feedback mb-3">Añada dato válido</div>
                        </div>
                    </div>

                    <div className="col-12 col-md-6 col-xl-4 mb-4 mb-md-0  no-was-validated">
                        <div>
                            <div className="form-group mb-3 position-relative no-check-valid ">
                                <div className="input-group input-group-lg">
                                    <span className="input-group-text text-theme border-end-0"><i className="bi bi-card-text"></i></span>
                                    <div className="form-floating" onFocus={handleLabel} onBlur={handleLabelOut}>
                                      <input
                                        type="text"
                                        name="phytosan_system"
                                        value={regGreenhouse.phytosan_system}
                                        onChange={handleChange}
                                        placeholder="Fitosanitario: <span class='placeh'>Riego, Pistola, Nebulización...</span>"
                                        className="form-control border-start-0"
                                        title="Fitosanitario" />
                                      <label>Fitosanitario: <span className='placeh'>Riego, Pistola, Nebulización...</span></label>
                                    </div>
                                </div>
                            </div>
                            <div className="invalid-feedback mb-3">Añada dato válido</div>
                        </div>
                    </div>


                </div>

                <h5 className='title'>Semilla</h5>
                <div className="row">

                    <div className="col-12 col-md-6 col-xl-4 mb-4 mb-md-0  no-was-validated">
                        <div>
                            <div className="form-group mb-3 position-relative no-check-valid ">
                                <div className="input-group input-group-lg">
                                    <span className="input-group-text text-theme border-end-0"><i className="bi bi-card-text"></i></span>
                                    <div className="form-floating">
                                        <input
                                            type="text"
                                            name="seed_brand"
                                            value={regGreenhouse.seed_brand}
                                            onChange={handleChange}
                                            placeholder="Marca semilla"
                                            className="form-control border-start-0" />
                                        <label>Marca</label>
                                    </div>
                                </div>
                            </div>
                            <div className="invalid-feedback mb-3">Añada dato válido</div>
                        </div>
                    </div>

                    <div className="col-12 col-md-6 col-xl-4 mb-4 mb-md-0  no-was-validated">
                        <div>
                            <div className="form-group mb-3 position-relative no-check-valid ">
                                <div className="input-group input-group-lg">
                                    <span className="input-group-text text-theme border-end-0"><i className="bi bi-card-text"></i></span>
                                    <div className="form-floating">
                                        <input
                                            type="text"
                                            name="seed_lot"
                                            value={regGreenhouse.seed_lot}
                                            onChange={handleChange}
                                            placeholder="Lote semilla"
                                            className="form-control border-start-0" />
                                        <label>Lote</label>
                                    </div>
                                </div>
                            </div>
                            <div className="invalid-feedback mb-3">Añada dato válido</div>
                        </div>
                    </div>
                </div>

                {/* <h5 className='title'>Ingeniero</h5>
                <div className='row'>
                    <div className="col-12 col-md-6 mb-2">
                        <div className="form-group mb-3 position-relative check-valid">
                            <div className="input-group input-group-lg">
                                <span className="input-group-text text-theme border-end-0"><i className="bi bi-globe"></i></span>
                                <div className="form-floating">
                                    <select
                                        className='form-select border-0'
                                        value={regGreenhouse.user_engineer_id}
                                        name="user_engineer_id" id="" onChange={handleChange}
                                        default
                                    >
                                        <option value="" />
                                        {engineer ? engineer.map((eng, index) => {
                                            return (
                                                <option key={index} value={eng.user_id}>{eng.name} {eng.lastname}</option>
                                            )
                                        }) : null}
                                    </select>
                                    <label htmlFor="country1">Ingeniero/a</label>
                                </div>
                            </div>
                        </div>
                        <div className="invalid-feedback mb-3">Add valid data </div>
                    </div>
                </div> */}

                {farmer ?
                <div>
                <h5 className='title'>Agricultor</h5>
                <div className='row'>
                    <div className="col-12 col-md-6 mb-2">
                        <div className="form-group mb-3 position-relative check-valid">
                            <div className="input-group input-group-lg">
                                <span className="input-group-text text-theme border-end-0"><i className="bi bi-globe"></i></span>
                                <div className="form-floating">
                                    <select
                                        className='form-select border-0'
                                        value={regGreenhouse.farmer_user_id}
                                        name="user_farmer_id" id="" onChange={handleChange}
                                        default
                                    >
                                        <option value="" />
                                        {farmer ? farmer.map((farm, index) => {
                                            return (
                                                <option key={index} value={farm.user_id}>{farm.name}</option>
                                            )
                                        }) : null}
                                    </select>
                                    <label htmlFor="country1">Agricultor/a</label>
                                </div>
                            </div>
                        </div>
                        <div className="invalid-feedback mb-3">Add valid data </div>
                    </div>
                </div></div>: null}

                <div className='row accept-cancel mt-2'>
                    <div className="col-auto mb-2">
                        <div>
                            <button className='btn btn-theme'
                                onClick={handleSubmit}
                                type="submit">Guardar</button>
                        </div>
                    </div>
                    <div className="col-auto mb-2">
                        <div>
                            <button className='btn btn-danger botonCancCreateGreen'
                                type='onSubmit'
                                onClick={() => navigate(-1)}>Cancelar</button><br />
                        </div>
                    </div>
                </div>
                <br/>

      </div>
    </main >
  );
};
