import { boardAPI } from '../api';
import config from '../config.json';
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import Cookies from "js-cookie";
import "../pages/board/board.scss";

export const Comments = ({ post, user }) => {
  const [comments, setComments] = useState([]);
  const [reload, setReload] = useState(false);
  const token = Cookies.get('token');
  const [newComment, setNewComment] = useState({
    user_id: user.id,
    fullname: `${user.name} ${user.lastname}`,
    user_image: user.img,
    post_id: post,
    body: ''
  });

  const handleChange = (e) => {
    setNewComment({ ...newComment, body: e.target.value });
  };

  const cleanComment = () => {
    setNewComment({ ...newComment, body: '' });
  };

  const deleteComment = (e) => {
    if (window.confirm('¿Quieres eliminar este comentario?')) {
      boardAPI.post(`/board/deleteComment`, {comment_id: e.currentTarget.id}, { headers: { Authorization: token } })
        .then((response) => {
          setReload(!reload);
        })
        .catch((error) => {
          console.log(error);
          console.log("Error al eliminar el comentario");
      });
    }
  };

  const saveComment = () => {
    if (window.confirm('¿Quieres publicar este comentario?')) {
      boardAPI.post('/board/addComment', newComment, { headers: { Authorization: token } })
        .then((response) => {
          cleanComment();
          setReload(!reload);
        })
        .catch((error) => {
          console.log("Error al publicar el comentario");
      });
    }
  };

  useEffect(() => {
    boardAPI.get(`/board/getCommentsForPost/${post}`, { headers: { Authorization: token } })
      .then((response) => {
        setComments(response.data.result);
      })
      .catch((error) => {
        console.log("No se pueden cargar lso comentarios");
      });
  }, [reload]);

  return (
    <div>
      {comments.length != 0 &&
        comments.map((comment) => {
          return (
            <div className='comment-card'>
              <div className='row'>
                <div className='col-auto comment-img'>
                  {!comment.user_image && <img className={`user-img-comm`} src={`${config.MEDIA_URL}/images/profile/default.jpg`} />}
                  {comment.user_image && <img className={`user-img-comm`} src={`${config.MEDIA_URL}/images/profile/${comment.user_image}`} />}
                </div>
                <div className='col sep-left'>
                  <div>
                    <span className='comment-user'>{comment.fullname}</span>
                    <span className='comment-date ms-2'>
                      {new Date(comment.created_at).toLocaleString('es-Es', {dateStyle: 'short', timeStyle: 'short'})}  
                    </span>
                    {comment.user_id == user.id &&
                      <span id={comment.id} className='float-end' onClick={deleteComment}>
                        <i className='bi bi-x-circle ms-2 text-danger clickable'></i>
                      </span>
                    }
                  </div>
                  <div className='col comment-body'>{comment.body}</div>
                </div>
              </div>
            </div>
          );
        })}
        <div className="new-comment" >
          <textarea className="new-comment-text" id="comment" rows="3" placeholder="Escribe un comentario" value={newComment.body} onChange={handleChange}></textarea>
          <span className='float-end'>
            <button disabled={newComment.body.length == 0 ? true : false} className="btn btn-warning me-2" onClick={cleanComment}>Limpiar</button>
            <button disabled={newComment.body.length == 0 ? true : false} className="btn btn-theme" onClick={saveComment}>Publicar</button>
          </span>
        </div>
    </div>
  );
};