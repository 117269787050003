import { messengerAPI } from '../../api';
import config from '../../config.json'
import React from "react";
import "./chatlist.scss"
import { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from 'js-cookie';

export const Chatlist = ({ setNotif }) => {
  const [chats, setChats] = useState([]);
  const navigate = useNavigate();
  const token = Cookies.get("token");

  const clearChat = (e) => {
    if (window.confirm('¿Quieres eliminar esta conversación?\nSólo se eliminará para ti.')) {
      const pair_id = e.currentTarget.id;
      
      messengerAPI.post(`/messenger/clearChat/${pair_id}`, {}, {headers: {Authorization: token}})
        .then((res) => {
          setChats(chats.filter((chat) => chat.chat_with_id != pair_id));
        })
        .catch((error) => {
          alert('Error al eliminar el chat.');
        });
    }
  };

  const chatwith = (e) => {
    navigate(`/chatwith/${e.currentTarget.id}`);
  };

  useEffect(() => {
    messengerAPI.get(`/messenger/chatlist`, {headers: {Authorization: token}})
      .then((res) => {
        setChats(res.data.result);
      })
      .catch((error) => {
        alert('Error al obtener los chats.');
      });
  }, []);

  return (
    <main className='main mainheight'>
      <div className='container'>

        <div className='row'>
          <div className="col text-center title-margin">
            <h4><span className="text-gradient-green-orange">Conversaciones abiertas</span></h4>
          </div>
        </div>

        <div className='row m-1'>
          {chats.length == 0 && <div className='col-12 text-center'>No hay conversaciones abiertas</div>}
          {chats.length ?
            chats.map((chat, index) => {
              return (
                <div className={`col-12 col-md-12 col-lg-12`} key={index}>
                  <div className={`row align-items-center chat-card ${chat.unread != 0 ? 'unread' : 'read'}`}>

                    <div className='col text-truncate'>
                      <div className='row clickable' id={chat.chat_with_id} onClick={chatwith}>
                        <div className='col-auto p-0 position-relative'>
                          {!chat.user_img && <img className={`chat-img`} src={`${config.MEDIA_URL}/images/profile/default.jpg`} />}
                          {chat.user_img && <img className={`chat-img`} src={`${config.MEDIA_URL}/images/profile/${chat.user_img}`} />}
                            {chat.unread > 0 && <span className={`ms-1 badge bg-danger rounded-pill chat-badge`}>{chat.unread}</span>}
                        </div>
                        <div className='col text-truncate'>
                          <h6 className='mb-1'>
                            {chat.name} {chat.lastname}
                          </h6>
                          <p className='chat-info'>
                            <span>Último mensaje: <b>
                              {chat.last_time ? new Date(chat.last_time).toLocaleString('es-Es', {dateStyle: 'short', timeStyle: 'short'}) : '-'}
                            </b></span>
                          </p>
                        </div>
                      </div>  
                    </div>

                    <div className='col-auto'>
                    <a
                      className='m-1'
                      href="#"
                      title='Eliminar conversación'
                      onClick={clearChat}
                      id={chat.chat_with_id}
                    >
                      <i className="bi bi-x-circle ba-xl text-danger"></i>
                    </a>
                    </div>
                  </div>

                </div>
              );
            }) : null
          }
        </div>
      </div>
    </main>
  );
};