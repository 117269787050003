import { greenhouseAPI } from '../api';
import config from '../config.json';
import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import Cookies from 'js-cookie';
import "./compPlagueAction.scss";

export const CompPlagueAction = ({risks}) => {
  const [plagues, setPlagues] = useState(null);
  const [risksSet, setRisksSet] = useState(null);
  const [modalPlague, setModalPlague] = useState(false);
  const [infoPlague, setInfoPlague] = useState({
    afection_type: '',
    causal_agent: '',
    description: '',       
    first_symptom: '',
    name: '',
    plague_id: 0,
    risk_level: 1
  });
  const token = Cookies.get('token');

  const reduceRisks = (risks) => {
    const risksParsed = JSON.parse(risks.replace(/'/g, '"'));
    let risksSet = {};

    if (risksParsed.hasOwnProperty('h')) {
      risksParsed.h.forEach((item)=>{
        if (typeof(item)==='number')
          if (item in risksSet)
            risksSet[item]['h'] += 1;
          else
            risksSet[item] = {h: 1};
      });
    }
    
    if (risksParsed.hasOwnProperty('t')) {
      risksParsed.t.forEach((item)=>{
        if (typeof(item)==='number') {
          if (!(item in risksSet)) {
            risksSet[item] = {t: 1};
          }
          else {
            if (risksSet[item].hasOwnProperty('t'))
              risksSet[item]['t'] += 1;
            else
              risksSet[item]['t'] = 1;
          }
        }  
      });
    }

    return risksSet;
  };

  const showPlague = (e) => {
    setInfoPlague(plagues.find((plague) => plague.plague_id == e.currentTarget.id));
    setModalPlague(true);
  };

  const closeModal = () => {
    setInfoPlague({
      afection_type: '',
      causal_agent: '',
      description: '',       
      first_symptom: '',
      name: '',
      plague_id: 0,
      risk_level: 1
    });
    setModalPlague(false);
  };

  useEffect(() => {
    greenhouseAPI
      .get("/plague/getAllPlagues", {headers: {Authorization: token}})
      .then((res) => {
        setPlagues(res.data);
      })
      .catch((error) => {
        console.log("ERRRRRRROOOOOOOOOOOOOOOOOR", error);
      });
  }, []);

  useEffect(() => {
    setRisksSet(reduceRisks(risks));
  }, []);

  return (
    <>
    <Modal show={modalPlague} onHide={closeModal}>
      <Modal.Header><div className="row">
        <div className='col-12'>
          <h4>{infoPlague.name}</h4>
          <p className="text-secondary small">
            {infoPlague.afection_type}
          </p>
        </div>
      </div>
      </Modal.Header>
      <Modal.Body>
        <img className='plague-img' alt={infoPlague.description} src={`${config.MEDIA_URL}/images/plague/${infoPlague.plague_id}.jpg`}/>
        <p>{infoPlague.description}</p>
        <ul className='field-list'>
          <li><span style={{fontWeight: '600'}}>Primer síntoma: </span>{infoPlague.first_symptom}</li>
          <li><span style={{fontWeight: '600'}}>Agente causal: </span>{infoPlague.causal_agent}</li>
          <li><span style={{fontWeight: '600'}}>Peligrosidad: </span>{infoPlague.risk_level}/5</li>
        </ul>
      </Modal.Body>
                <Modal.Footer>
                  <Button className="btn btn-theme btn-sm" onClick={closeModal}>
                    Cerrar
                  </Button>&nbsp;
                </Modal.Footer>
              </Modal>

    <div className="row">
      <div className="col-12 col-md-6">
        <div className="card bg-white border-0 z-index-1">
          <div className="card-body">
            <h3>Riesgo de plagas</h3>
            {plagues && risksSet &&
            Object.keys(risksSet).map((risk) => {
              const plague = plagues.find((plague) => plague.plague_id == risk);
              const t = risksSet[risk].hasOwnProperty('t') ? risksSet[risk].t : 0;
              const h = risksSet[risk].hasOwnProperty('h') ? risksSet[risk].h : 0;
              const levelColor = (t > 3 || h > 3)
                ? 'risk-high' 
                : (t > 1 || h > 1) ? 'risk-medium' : '';
              return (
                <div className='row'>
                  <div className='col-12'>
                    <div id={risk} className={`plague ${levelColor}`} onClick={showPlague}>
                      <span className='plague-name'>{plague.name}</span>
                      <span title='Peligrosidad de la plaga' className='plague-danger float-end'>
                        <i className={
                          `bi bi-exclamation-triangle fs-6 me-2 ${
                            plague.risk_level > 3
                            ? 'text-danger' 
                            : plague.risk_level > 1
                              ? 'text-warning'
                              : 'text-success'
                          }`}></i>
                        {plague.risk_level}/5</span>
                      <hr style={{margin: '2px 0'}}/>
                      <div>
                      {risksSet[risk].hasOwnProperty('t') && <p><i className='bi bi-thermometer-half text-warning me-2'></i>Riesgo por temperatura: <span style={{fontWeight: '600'}}>{risksSet[risk].t}/5</span></p>}
                      {risksSet[risk].hasOwnProperty('h') && <p><i className='bi bi-droplet text-primary me-2'></i>Riesgo por humedad: <span style={{fontWeight: '600'}}>{risksSet[risk].h}/5</span></p>}
                      <span className='floating-button'>+ info</span>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })
            }
          </div>
        </div>
      </div>
    </div>
    </>
  );
};
