import React, { useState, useEffect } from "react";
import jwtDecode from "jwt-decode";
import { GreenInfoComp } from "../../components/GreenInfoComp";
import { Row, Col, Modal, Button } from "react-bootstrap";
import { useNavigate, useParams, Link } from "react-router-dom";
import { greenhouseAPI, userAPI } from '../../api';
import { WeatherAPI } from "../../components/weatherAPI/WeatherAPI";
import Cookies from 'js-cookie';
import "./oneGreen.scss";
import { CompPlagueAction } from "../../components/CompPlagueAction";
import vega11 from "../../assets/img/Vega11.png";
import config from '../../config.json';

export const Greenhouse = () => {
  const [green, setGreen] = useState(null);
  const [dataSets, setDataSets] = useState(null);
  const [greenhouses, setGreenHouses] = useState();
  const [ghUsers, setGhUsers] = useState(null);
  const { greenhouse_id } = useParams();
  const [load, setLoad] = useState(true);
  const [greenhouseModal, setGreenhouseModal] = useState(false);
  const [greenhouseModalMap, setGreenhouseModalMap] = useState('status_img');
  const [crops, setCrop] = useState();
  const [showCampaignChange, setCampaignChange] = useState(false);
  const [showPlagueAction, setShowPlagueAction] = useState(false);
  const [contactList, setContactList] = useState(null);
  const [rolesList, setRolesList] = useState(null);

  const [masInfoInter, setMasInfoInter] = useState(false);
  const [masInfoExt, setMasInfoExt] = useState(false);

  const navigate = useNavigate();

  const [show, setShow] = useState(false);
  const [showGrant, setShowGrant] = useState(false);
  const token = Cookies.get("token");

  const [selection, setSelection] = useState(null);
  const [roleSel, setRoleSel] = useState(1);
  const [busqueda, setBusqueda] = useState("");

  const getGrantedUsers = () => {
    greenhouseAPI
        .get(
          `/greenhouse/getUsersByGreenhouse/${green.greenhouse_id}`,
          {headers: {authorization: token}}
        )
        .then((res) => {
          if (res.data.result) {
            setGhUsers(res.data.result);
          }
        })
        .catch((error) => {
          console.log("ERROR: getUsersByGreenhouse");
        });
  };

  const handleRole = (e) => {
    e.preventDefault();
    setRoleSel(e.currentTarget.value);
  };

  const handleSelect = (e) => {
    e.preventDefault();
    if (selection == e.currentTarget.id)
      setSelection(null);
    else
      setSelection(e.currentTarget.id);
  };

  const handleGrant = (e)=> {
    e.preventDefault();

    const data = {
      contact_id: selection,
      gh_id: greenhouse_id,
      gh_name: green.name_greenhouse,
      role_id: roleSel
    };

    greenhouseAPI
      .post(`/greenhouse/grantAccess`, data, {headers: {Authorization: token}})
      .then((res) => {
        getGrantedUsers();
        handleCloseGrant();
      })
      .catch((error) => {
        console.log('ERROR: grantAccess');
        alert("Error al agregar usuario");
      });
  }

  const handleRevoke = (e)=> {
    e.preventDefault();

    if (window.confirm('¿Seguro que desea eliminar este permiso?')) {
      const contact_id = e.currentTarget.id;

      greenhouseAPI
        .post(`/greenhouse/revokeAccess`, {contact_id: contact_id, gh_id: greenhouse_id}, {headers: {Authorization: token}})
        .then((res) => {
          setGhUsers(ghUsers.filter((item)=>item.user_id != contact_id));
        })
        .catch((error) => {
          console.log(error);
          alert("Error al eliminar permiso");
        });
    }
  }

  // useEffect(() => {
  //   handleResize();
  //   window.addEventListener("resize", handleResize);
  // }, []);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleShowGrant = () => setShowGrant(true);
  const handleCloseGrant = (e) => {
    setShowGrant(false);
    setSelection(null);
    setRoleSel(1);
  };

  const showModalGrant = (evt) => {
    userAPI
      .get(`/user/allEngineersFromFarmer`, {headers: {Authorization: token}})
      .then((res) => {
        setContactList(res.data.result.filter(item => !ghUsers.find(user => user.user_id === item.user_id)));
        handleShowGrant();
      })
      .catch((error) => {
        console.log('ERROR: allEngineersFromFarmer');
      });
  };

  const [user_id, setUser_id] = useState();
  useEffect(() => {
    if (token) {
      setUser_id(jwtDecode(token).user.id);
    }
  }, [token]);

  useEffect(() => {
    greenhouseAPI
      .get(
        `/greenhouse/showOneGreenhouse/${greenhouse_id}`,
        {headers: {authorization: token}}
      )
      .then((res) => {
        setGreen(res.data.result[0]);
        setGreenHouses([res.data.result[0],]);
      })
      .catch((error) => {
        console.log("ERROOOOOOOOOR DE INVERNADERO");
      });
  }, [load]);

  useEffect(() => {
    if (green) {
      greenhouseAPI
        .get(`/greenhouse/showLastMeasurements/${green.season_id}`, {headers: {authorization: token}})
        .then((res) => {
          if (res.data.result) {
            setDataSets(res.data.result);
          }
        })
        .catch((error) => {
          console.log("ERROR CARGANDO LECTURAS");
        });
    }
  }, [green]);

  //--------------traemos todos los tipos de cultivo
  useEffect(() => {
    greenhouseAPI
      .get(`/greenhouse/showAllCrops`)
      .then((res) => {
        if (res.data) {
          setCrop(res.data);
        }
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    if (green) {
      getGrantedUsers();
    }
  }, [green]);

  useEffect(() => {
    greenhouseAPI
      .get(`/greenhouse/getRoles`, {headers: {authorization: token}})
      .then((res) => {
        if (res.data.result) {
          setRolesList(res.data.result);
        }
      })
      .catch((error) => {
        console.log("ERROR: getRoles");
      });
  }, [token]);

  const delGreen = (id) => {
    if (window.confirm('Va a eliminar este invernadero de forma irreversible\n¿SEGURO QUE DESEA ELIMINAR?')) {
      greenhouseAPI
        .post(`/greenhouse/deleteGreenhouse/${id}`, {}, {headers: {authorization: token}})
        .then((res) => {
          navigate(`/userFarmer`);
        })
        .catch((error) => {
          console.log("ERROR DE BORRADO DE INVERNADERO", error);
        });
    }
  };

  function showModal(evt) {
    let ghid = evt.currentTarget.dataset.greenhouseid;

    greenhouseAPI
      .get(`/greenhouse/showOneGreenhouse/${ghid}`, {headers: {authorization: token}})
      .then((res) => {
        res.data.result[0].currentMap = res.data.result[0].status_img;
        setGreenhouseModal(res.data.result[0]);
        const newDate = new Date(res.data.result[0].run_time);
        // setLocalDate(newDate.toLocaleString());
        setGreenhouseModalMap('status_img');
        handleShow();
      })
      .catch((error) => {
        console.log("error recibiendo datos del invernadero");
      });
  }

  function changeDataset(evt) {
    setGreenhouseModal({...greenhouseModal, ...dataSets[evt.currentTarget.value]});
    const newDate = new Date(dataSets[evt.currentTarget.value].run_time);
    // setLocalDate(newDate.toLocaleString());
    // setGreenhouseModalMap('status_img');
  }

  function changeMap(evt) {
    if (evt.currentTarget.dataset.map) {
      setGreenhouseModalMap(evt.currentTarget.dataset.map);
    }
  }

  function toggleCampaignChange() {
    if (showCampaignChange) {
      setCampaignChange(false);
    } else {
      setCampaignChange(true);
    }
  }

  function Capitalize(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  const handleChangeCrop = (e) => {
    const { name, value } = e.target;
    setGreen({ ...green, ['crop_id']: value });

  }

  const handleSubmit = (e) => {
    e.preventDefault();
    toggleCampaignChange();
    if (green.production != green.crop_id){
      green.production = green.crop_id;
    }
    if (green.irrigation_system == 'null'){green.irrigation_system = ''}
    if (green.fertilize_system == 'null'){green.fertilize_system = ''}
    if (green.fertilize_type == 'null'){green.fertilize_type = ''}
    if (green.light_system == 'null'){green.light_system = ''}
    if (green.phytosan_system == 'null'){green.phytosan_system = ''}
    if (green.seed_brand == 'null'){green.seed_brand = ''}
    if (green.seed_lot == 'null'){green.seed_lot = ''}
    if (green.windows == 'null'){green.windows = ''}
    if (green.lat == null || green.lon == null)
      green['location'] = "";
    else
      green['location'] = green.lat + ',' + green.lon;

    const regGreenhouse = (({
      greenhouse_id,
      user_farmer_id,
      name_greenhouse,
      year,
      production,
      temperature,
      location,
      co2,
      crop_id,
      humidity_soil,
      humidity_air,
      quality_soil,
      irrigation_system,
      light_system,
      fertilize_type,
      fertilize_system,
      phytosan_system,
      seed_brand,
      seed_lot,
      user_engineer_id,
      health,
      windows
    }) => 
      ({
        greenhouse_id,
        user_farmer_id,
        name_greenhouse,
        year,
        production,
        temperature,
        location,
        co2,
        crop_id,
        humidity_soil,
        humidity_air,
        quality_soil,
        irrigation_system,
        light_system,
        fertilize_type,
        fertilize_system,
        phytosan_system,
        seed_brand,
        seed_lot,
        user_engineer_id,
        health,
        windows})
        )(green);
    console.log('SAVING GREENHOUSE');
    greenhouseAPI
        .post(`/greenhouse/editGreenhouse/${regGreenhouse.greenhouse_id}`, regGreenhouse, {headers: {authorization: token}})
        .then((res) => {
          navigate(`/userFarmer`);
        })
        .catch((error) => {
          console.log("ERROR: editGreenhouse");
        });
  }


  return (

    <main className='main mainheight'>
      <div className='container'>
        <Row>
          <Col>
            {greenhouses && greenhouses.length > 0 ? (
              <Modal show={show} onHide={handleClose}>

                <Modal.Header><div className="row">
                  <div className='col-12'>
                    {greenhouseModal && (
                      <><h4>{greenhouseModal.name_greenhouse}</h4>
                        <label className="text-secondary me-2">Análisis: </label>
                        <select id="datasets" onChange={changeDataset} className="form-select-sm">
                          {dataSets && 
                            dataSets.map((data, index) =>{
                              return <option key={index} value={index} id={index}>{(new Date(data.run_time)).toLocaleString().slice(0, -3)}</option>
                            })
                          }
                        </select></>
                    )}
                  </div>
                </div>
                </Modal.Header>

                <Modal.Header>
                  <div className="container">
                  <div className="row gx-2">
                    {greenhouseModal.status_img && (
                      <div className='col'>
                        <button className='btn btn-theme btn-sm w-100' onClick={changeMap} data-map='status_img'>
                          <i className='bi bi-thermometer-half fs-4 d-block'></i>TEM
                        </button>
                      </div>)
                    }
                    {greenhouseModal.diseases_img && (
                      <div className='col'>
                        <button className='btn btn-theme btn-sm w-100' onClick={changeMap} data-map='diseases_img' translate="no">
                          <i className='bi bi-moisture fs-4 d-block'></i>HUM
                        </button>
                      </div>)
                    }
                    {greenhouseModal.co2_img && (
                      <div className='col'>
                        <button className='btn btn-theme btn-sm w-100' onClick={changeMap} data-map='co2_img'>
                          <i className='bi bi-balloon fs-4 d-block'></i>CO2
                        </button>
                      </div>)
                    }
                    {greenhouseModal.plagues_img && (
                      <div className='col'>
                        <button className='btn btn-theme btn-sm w-100' onClick={changeMap} data-map='plagues_img'>
                          <i className='bi bi-radioactive fs-4 d-block'></i>ENF
                        </button>
                      </div>)
                    }
                    {greenhouseModal.growth_img && (
                      <div className='col'>
                        <button className='btn btn-theme btn-sm w-100' onClick={changeMap} data-map='growth_img'>
                          <i className='bi bi-sort-up fs-4 d-block'></i>CRE
                        </button>
                      </div>)
                    }
                    {greenhouseModal.count_img && (
                      <div className='col'>
                        <button className='btn btn-theme btn-sm w-100' onClick={changeMap} data-map='count_img'>
                          <i className='bi bi-basket fs-4 d-block'></i>REC
                        </button>
                      </div>
                    )
                    }
                  </div>
                  </div>
                </Modal.Header>

                <Modal.Body>
                  {greenhouseModal[greenhouseModalMap] && (
                    <img
                      src={`data:image/png;base64,${btoa(String.fromCharCode(...new Uint8Array(greenhouseModal[greenhouseModalMap].data)))}`}
                      className="imgModal"
                    />
                  )}
                  {/* <Plot
                    data={ [ data_z1, data_z2, data_z3 ]}
                    layout={{
                      width: plotW,
                      height: plotH,
                      l: 0, r: 0, b: 0, t: 0, pad: 0,
                      title: 'Basic Plot'
                    }}
                    config={{scrollZoom: true, displaylogo: false}}
                  /> */}
                </Modal.Body>

                <Modal.Footer>
                  <Button className="btn btn-theme btn-sm" onClick={handleClose}>
                    Cerrar
                  </Button>&nbsp;
                </Modal.Footer>

              </Modal>
            ) : null}
            {/* --------------- FIN DEL MODAL------------------- */}
          </Col>
        </Row>

        { /* Modal Agregar permisos ----------------------------------------------- */ }
        <Row>
          <Col>
            {greenhouses && greenhouses.length > 0 ? (
              <Modal show={showGrant} onHide={handleCloseGrant}>

                <Modal.Header>
                  <div className="row">
                    <div className='col-12'>
                      <h4>CONCEDER ACCESO</h4>
                    </div>
                  </div>
                </Modal.Header>

                <Modal.Body>
                  <div className="row"><div className="col">
                  {contactList == null && (
                    <div className="row">
                      <div className="col text-center text-secondary fw-bold small">
                        CARGANDO...
                      </div>
                    </div>
                  )}

                  {contactList && contactList.length == 0 && (
                    <div className="row">
                      <div className="col text-center text-secondary fw-bold small">
                        NO TIENES CONTACTOS A LOS QUE CONCEDER ACCESO.
                      </div>
                    </div>
                  )}

                  {contactList && contactList.length > 0 && 
                    (
                      <>
                        <div className='row input-filter'>
                          <div className="col">
                            <div className="input-group input-group-sm rounded">
                              <span className="input-group-text text-theme"><i className="bi bi-search"></i></span>
                              <input className="form-control"
                                type="search"
                                placeholder="Filtrar"
                                onChange={(e) => setBusqueda(e.target.value)} />
                            </div>
                          </div>
                        </div>

                        {contactList.filter((contact) => {
                          if (busqueda === "")
                            return contact;
                          else if (
                            contact.name.toLowerCase().includes(busqueda.toLowerCase()) ||
                            contact.lastname.toLowerCase().includes(busqueda.toLowerCase()) ||
                            contact.email.toLowerCase().includes(busqueda.toLowerCase())
                          )
                            return contact;
                        }).map((contact, index)=>{
                          return (
                            <div
                              id={contact.user_id}
                              className={`row clickable select-item ${selection == contact.user_id ? 'selected' : ''}`}
                              key={index}
                              onClick={handleSelect}
                            >
                                <div className="col-auto">
                                  {!contact.user_img && <img className={`profile-img`} src={`${config.MEDIA_URL}/images/profile/default.jpg`} />}
                                  {contact.user_img && <img className={`profile-img`} src={`${config.MEDIA_URL}/images/profile/${contact.user_img}`} />}
                                </div>
                                <div className='col px-0'>
                                  <div className="fw-bold">
                                    {contact.name} {contact.lastname}
                                    <div className="small text-secondary">
                                      {contact.email}
                                    </div>
                                  </div>
                              </div>
                              <div className="col-auto py-2">
                                {selection == contact.user_id
                                  ? <i className="bi bi-check-circle text-success"></i>
                                  : <i className="bi bi-circle text-secondary"></i>
                                }
                              </div>
                            </div>
                          )
                        })}
                      </>
                    )
                  }
                  </div></div>
                </Modal.Body>

                <Modal.Footer>
                <div className="row w-100">
                  {rolesList && (
                    <>
                    <div className="col">
                      <div className="form-group position-relative check-valid">
                        <div className="input-group input-group-sm">
                          <span className="input-group-text text-theme border-end-0"><i className="bi bi-gear"></i></span>
                          <div className="form-floating">
                            <select
                              className="form-select border-0 border-start-0"
                              name="profile"
                              title="profile"
                              value={roleSel}
                              onChange={handleRole}
                            >
                              {rolesList.map((role, index) => {
                                return (
                                  <option key={index} value={role.id}>{role.long_name}</option>
                                )
                              })}
                            </select>
                            <label>Tipo de permiso</label>
                          </div>
                        </div>
                      </div>
                    </div>
                    </>
                  )}
                  <div className="col-4">
                    <Button className="btn btn-success text-white btn-sm w-100 mb-1 py-1" onClick={handleGrant} disabled={rolesList && selection ? false : true}>
                      Agregar
                    </Button>
                    <Button className="btn btn-danger btn-sm w-100 py-1" onClick={handleCloseGrant}>
                      Cerrar
                    </Button>
                  </div>
                </div>
                </Modal.Footer>

              </Modal>
            ) : null}
          </Col>
        </Row>
        { /* Fin Modal Agregar permisos -------------------------------------------- */ }

        <div className='row'>
          <div className="col text-center title-margin">
            <h4><span className="text-gradient-green-orange">Invernadero</span></h4>
          </div>
        </div>

        {green ? (
          <div>
            <div className='row'>
              <div className='col-md-6 col-12'>
                <div className="card bg-white border-0 z-index-1">
                  <div className="card-body">
                    <div className='row'>
                      <div className='col'>
                        <h2>{green.name_greenhouse}</h2><br />
                      </div>
                    </div>
                    <div className='row' >
                    <div className='col-auto'>
                    <img src={vega11} style={{maxHeight:'35px', marginTop: '6px', marginBottom: '4px'}} />
                    </div>
                      <div className='col-auto'>
                      {green.risks &&
                          <button
                            onClick={() => setShowPlagueAction(!showPlagueAction)}
                            className="btn btn-danger btn-sm me-2"
                          >
                            <i className="bi bi-bug text-white pe-1"></i>
                            <span className="text-white">En riesgo</span>
                          </button>
                      }
                      {green.status_img &&
                          <button
                            onClick={showModal}
                            className='btn btn-success btn-sm'
                            data-greenhouseid={green.greenhouse_id}
                          >
                            <i className="bi bi-activity text-white pe-1"></i>
                            <span className="text-white">Estado</span>
                          </button>
                      }
                      {!green.status_img && !green.risks &&
                          <p className="alert alert-secondary p-2">
                            No hay datos disponibles
                          </p>
                      }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <br />
            <div>
              {showPlagueAction && (
                <CompPlagueAction
                  risks={green.risks}
                />
              )}
              <br />
            </div>
            <div className='row'>
              <div className='col-md-6 col-12'>
                <div className="card bg-white border-0 z-index-1">
                  <div className="card-body">
                    <GreenInfoComp
                      masInfoExt={masInfoExt}
                      masInfoInter={masInfoInter}
                      setMasInfoInter={setMasInfoInter}
                      green={green}
                      setgreen={setGreen}
                      setLoad={setLoad}
                      load={load}
                    />
                  </div>
                </div>
              </div>
            </div>
            <br />
            <div className='row'>
              <div className='col-md-6 col-12'>
                <div className="card bg-white border-0 z-index-1">
                  <div className="card-body">
                    <h5 className="fw-medium mb-0">Condiciones externas</h5>
                    <hr />
                    <WeatherAPI
                      masInfoExt={masInfoExt}
                      masInfoInter={masInfoInter}
                      setMasInfoExt={setMasInfoExt}
                      greenhouse={green}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

        ) : null}
        <br />
          <div className="col-12 col-md-6 col-lg-4">
            <div className="card">
              <div className="card-body">
                <h5 className="fw-medium mb-0">
                  {'Contactos con acceso: '}
                  <span className="badge text-bg-secondary float-end">{ghUsers && ghUsers.length > 0 ? ghUsers.length : 'Ninguno'}</span>
                </h5>
                <hr />

                {ghUsers && ghUsers.length > 0 && (
                  ghUsers.map((ghUser) => {
                    return (
                      <>
                      <div className={`row`} key={ghUser.user_id}>
                        <div className={`col-auto`}>
                          {!ghUser.user_img && <img className={`profile-img`} src={`${config.MEDIA_URL}/images/profile/default.jpg`} />}
                          {ghUser.user_img && <img className={`profile-img`} src={`${config.MEDIA_URL}/images/profile/${ghUser.user_img}`} />}
                        </div>
                        <div className='col px-0'>
                          <div className="fw-bold">
                            {ghUser.name} {ghUser.lastname}
                          </div>
                          <div className={`py-1 m-0 text-white badge ${ghUser.role_id == 1 ? 'text-bg-success' : 'text-bg-danger'}`}>
                            {ghUser.role_id == 1 ? 'Consulta' : 'Control'}
                          </div>
                        </div>
                        <div className="col-3 text-end">
                          <a
                            className='d-inline-block m-1'
                            href="#"
                            title='Revocar acceso'
                            id={ghUser.user_id}
                            onClick={handleRevoke}
                          >
                            <i className="bi bi-x-circle ba-xl text-danger"></i>
                          </a>
                        </div>
                      </div>
                      <hr />
                      </>
                    );
                  })
                )}

              <div className="row">
                <div className="col">
                  <button className="btn btn-theme btn-sm w-100" onClick={showModalGrant} disabled={(ghUsers && ghUsers.length >= config.MAX_GRANT_USERS) ? true : false}>
                    {(ghUsers && ghUsers.length >= config.MAX_GRANT_USERS) ? 'Máximo alcanzado' : 'Agregar'}
                  </button>
                </div>
              </div>

            </div>
          </div>
        </div>
        
        <br />

        <div className='row mb-3'>
          <div className='col-md-6 col-12'>
            <div className="card bg-white border-0 z-index-1">
              <div className="card-body">
                <div className='row'>
                  <div className='col-auto'>
                    <button
                      onClick={() => delGreen(green.greenhouse_id)}
                      className="btn btn-theme btn-sm bg-red"
                    >
                      Eliminar
                    </button>
                  </div>
                  <div className='col-auto'>
                    <button
                      onClick={() => navigate(`/editGreenhouse/${greenhouse_id}`)}
                      className="btn btn-theme btn-sm"
                    >
                      Editar
                    </button>
                  </div>
                  <div className='col-auto'>
                    <button
                      onClick={() => toggleCampaignChange()}
                      className="btn btn-theme btn-sm"
                    >
                      Nueva Campaña
                    </button>
                  </div>
                </div>
                {showCampaignChange ? (
                <div>
                  <div className='row'>
                    <div className='col-auto'>
                      <br />
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-auto'>
                        <div>
                          <div className="form-group mb-3 position-relative check-valid is-valid">
                            <div className="input-group input-group-lg">
                              <span className="input-group-text text-theme border-end-0"><i className="bi bi-card-text"></i></span>
                              <div className="form-floating">
                                <select
                                  name="crop_id"
                                  value={green.crop_id}
                                  placeholder="Tipo Cultivo"
                                  required
                                  onChange={handleChangeCrop}
                                  className="form-select border-0 border-start-0" >
                                  <option value="">Seleccione...</option>

                                  {crops ? crops.map((crop, index) => {
                                    return (
                                      <option key={index} value={crop.crop_id}>{Capitalize(crop.crop_name)}</option>
                                    )
                                  }) : null}
                                </select>
                                <label>Cultivo/s</label>
                              </div>
                            </div>
                          </div>
                          <div className="invalid-feedback mb-3">Añada dato válido</div>
                        </div>
                      </div>
                      <div className='col-auto'>
                        <button className='btn btn-theme btn-sm'
                          onClick={handleSubmit}
                          type="submit">Guardar</button>
                          
                      </div>
                  </div>
                  <div className='row'>
                    <div className='col-auto'>
                    <p className='text-secondary small'>El cambio de cultivo implica reinicio de estado del invernadero</p>
                    </div>
                  </div>
                </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>

      </div>
    </main>
  );
};
