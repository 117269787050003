import { boardAPI } from '../../api';
import config from '../../config.json';
import React from "react";
import { Modal, Button } from "react-bootstrap";
import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { LinkPreview } from "../../components/linkPreview";
import { Comments } from "../../components/Comments";
import Cookies from 'js-cookie';
import "./board.scss"
import jwtDecode from 'jwt-decode';

export const Board = ({modalPost, setModalPost}) => {
  const token = Cookies.get('token');
  const user_id = jwtDecode(token).user.id;
  const [user, setUser] = useState();
  const [showLoader, setShowLoader] = useState(false);
  const [posts, setPosts] = useState([]);
  const [apiLock, setApiLock] = useState(false);
  const [fetched, setFetched] = useState(false);
  const [showComments, setShowComments] = useState({});
  const [editPost, setEditPost] = useState({
    id: null,
    user_id: user_id,
    body: '',
    image: null,
    filetype: null,
    ext_link: null,
    is_public: 0,
    num_comments: 0
  });
  const [modalFunc, setModalFunc] = useState('Nueva');
  const [noPostMsg, setNoPostMsg] = useState('No hay noticias');
  const [postImage, setPostImage] = useState(null);
  const [reload, setReload] = useState();
  const [doScroll, setDoScroll] = useState(false); 
  const scrollRef = useRef();
  const navigate = useNavigate();

  const handleEdit = (e) => {
    const menu = document.getElementById(`menu${e.currentTarget.id}`);
    menu.classList.remove('menu-show');
    e.stopPropagation();
    const post = posts.find(post => post.id == e.currentTarget.id)
    if (post.image != null)
      setPostImage(`${config.MEDIA_URL}/images/post/${post.image}`);
    else
      setPostImage(null);
    setEditPost(post);
    setModalPost(true);
    setModalFunc('Editar');
  };

  const handleClose = (e) => {
    if (window.confirm('¿Cerrar SIN guardar? Perderás todos los cambios.')) {
      setEditPost({
        id: null,
        user_id: user.id,
        body: '',
        image: null,
        filetype: null,
        ext_link: null,
        is_public: 0,
        num_comments: 0
      });
      setPostImage(null);
      setModalPost(false);
      setModalFunc('Nueva');
    }
  };

  const toggleComments = (e) => {
    const arrow = document.getElementById(`${e.currentTarget.id}_arrow`);
    const panel = document.getElementById(`${e.currentTarget.id}_panel`);
    arrow.classList.toggle('rotate-up');
    panel.classList.toggle('opened');
    setShowComments({...showComments, [e.currentTarget.id]: !showComments[e.currentTarget.id]});
  };

  const handleDelete = (e) => {
    const menu = document.getElementById(`menu${e.currentTarget.id}`);
    menu.classList.remove('menu-show');
    e.stopPropagation();

    const post_id = e.currentTarget.id;
    if(window.confirm('¿Estás seguro de que quieres eliminar esta publicación?')) {
      boardAPI.post('/board/deletePost', {post_id: post_id}, {headers: {Authorization: token}})
        .then((res) => {
          setPosts(posts.filter(post => post.id != post_id));
        })
        .catch((error) => {
          alert('Error al eliminar la publicación');
        });
    }
  };

  const resizeTextarea = (elem) => {
    elem.style.height = 'auto';
    elem.style.height = (elem.scrollHeight)+"px";
  };

  const handleChange = (e) => {
    if (e.target.id == 'body')
      resizeTextarea(e.target)
    setEditPost({...editPost, [e.target.id]: e.target.value})
  };

  const handleMenu = (e) => {
    const menu = document.getElementById(`menu${e.currentTarget.id}`);
    menu.classList.toggle('menu-show');
    e.stopPropagation();

  };

  const selectPhoto = (e) => {
    e.currentTarget.children[0].click();
  };

  const addImage = (e) => {
    e.preventDefault();
    if (e.target.files[0].size > 20 * 1024 * 1024) {
      alert('El archivo no puede superar los 20MB');
      return;
    }

    if (!e.target.files[0].type.startsWith('image') && !e.target.files[0].type.startsWith('video')) {
      alert('Tipo de archivo no válido');
      return;
    }
      
    setEditPost({...editPost, image: e.target.files[0], filetype: e.target.files[0].type});
    setPostImage(URL.createObjectURL(e.target.files[0]));
  };

  const deleteImage = (e) => {
    setEditPost({...editPost, image: null, filetype: null});
    setPostImage(null);
  };

  const handleLinkChange = (e) => {
    const link = document.getElementById('ext_link').value;
    setEditPost({...editPost, ext_link: link});
  };

  const deleteLink = () => {
    setEditPost({...editPost, ext_link: null});
  };

  const doReload = () => {
    setReload(reload == undefined ? !reload : true);
  };

  const savePost = (e) => {
    const form = new FormData();
    if (window.confirm('¿Quieres publicar?')) {
      setShowLoader(true);
      if (typeof(editPost.image) == 'object' && editPost.image != null) {
        const {image, ...data} = editPost;
        form.append('data', JSON.stringify(data))
        form.append('file', editPost.image);
      }
      else
        form.append('data', JSON.stringify(editPost));

        boardAPI.post('/board/newPost', form, {headers: {Authorization: token}})
        .then((res) => {
          setReload(reload == undefined ? !reload : true);
          setEditPost({
            id: null,
            user_id: user.id,
            body: '',
            image: null,
            filetype: null,
            ext_link: null,
            is_public: 0,
            num_comments: 0
          });
          setShowLoader(false);
          setPostImage(null);
          setModalPost(false);
          setModalFunc('Nueva');
        })
        .catch((error) => {
          setShowLoader(false);
          alert('Error al guardar la publicación');
        });
    }
  };
  
  useEffect(() =>{
    if (scrollRef != undefined && scrollRef.current != undefined) 
      scrollRef.current.scrollIntoView();
  }, [doScroll]);

  useEffect(() => {
    boardAPI.get('/board/getUserRole', {headers: {Authorization: token}})
      .then((res) => {
        setUser({...jwtDecode(token).user, role: res.data.role});
      })
      .catch((error) => {
        console.log('Error al obtener el rol de usuario.');
      });
  }, []);

  useEffect(() => {
    boardAPI.get('/board/getPostsForUser', {headers: {Authorization: token}})
      .then((res) => {
        const posts_contact = [];
        const show_comments = {};
        res.data.result.forEach(element => {
          posts_contact.push({...element, ...res.data.contact_data[element.user_id]});
          show_comments[element.id] = false;
        });
        setDoScroll(!doScroll);
        setShowComments(show_comments);
        setPosts(posts_contact);
        setFetched(true);
      })
      .catch((error) => {
        setNoPostMsg('Error al cargar las publicaciones');
        console.log(error);
        setFetched(true);      
      });
  }, [user, reload]);

  useEffect(() => {
    document.addEventListener('click', (e) => {
      const menu = document.getElementsByClassName('menu-show');
      menu.forEach(m => {
        if (e.target !== m)
          m.classList.remove('menu-show');
      });
    });
  }, [posts]);

  useEffect(() => {
    const elem = document.getElementById('body');
    if (elem)
      resizeTextarea(elem); 
  }, [editPost]);

  return (
    <main className='main mainheight'>
      <div className='container pt-3'>

      <Modal
        show={modalPost}
        className="text-body text-center"
        centered
        onHide={handleClose}
        backdrop="static"
        scrollable
      >
          <Modal.Header>
            <h3>{modalFunc} publicación</h3>
          </Modal.Header>
          <Modal.Body>
            {editPost != null && modalPost &&
              <div className='col-12 mt-4'>
              <div className='card text-start'>
                <div className='post-header'>
                  <span className='float-start header-name'>
                  {!user.img && <img className={`user-img me-2`} src={`${config.MEDIA_URL}/images/profile/default.jpg`} />}
                  {user.img && <img className={`user-img me-2`} src={`${config.MEDIA_URL}/images/profile/${user.img}`} />}
                  Tú
                  </span>

                  <span className='float-end header-time'>
                    {user.role == 'private'
                    ? <span>Para tus contactos</span>
                    : <select className="input_public" type="select" id="is_public" name="is_public" value={editPost.is_public} onChange={handleChange}>
                        <option value="0">Para tus contactos</option>
                        <option value="1">Para todos los usuarios</option>
                      </select>
                    
                    }
                  </span>

                </div>
                {!postImage &&
                  <div className='add-image clickable' onClick={selectPhoto}>
                    <input type="file" name="image" id="photo" accept="image/*, video/*" onChange={addImage} hidden />
                    <p>Añadir imagen</p>
                    <i className='bi bi-file-earmark-arrow-up-fill fs-1'></i>
                  </div>
                }

                {postImage && editPost.filetype.startsWith('image') &&
                  <div className='post-image edit' style={{ backgroundImage: `url(${postImage})`}}>
                    <div className='bg-blur'>
                      <img className='' src={postImage} />
                    </div>
                    <div className='image-options'>
                      <div>
                        <i className='bi bi-file-earmark-arrow-up-fill fs-1 clickable me-4' onClick={selectPhoto}>
                          <input type="file" name="image" id="photo" accept="image/*, video/*" onChange={addImage} hidden />
                        </i>
                        <i className='bi bi-x-circle-fill fs-1 clickable ms-4' onClick={deleteImage}></i>
                      </div>
                    </div>
                  </div>
                }

                {postImage && editPost.filetype.startsWith('video') &&
                  <div className='post-image edit' style={{backgroundColor: '#222222'}}>
                    <div className='bg-blur'>
                      <video autoPlay muted className='' src={postImage} />
                    </div>
                    <div className='image-options'>
                      <div>
                        <i className='bi bi-file-earmark-arrow-up-fill fs-1 clickable me-4' onClick={selectPhoto}>
                          <input type="file" name="image" id="photo" accept="image/*, video/*" onChange={addImage} hidden />
                        </i>
                        <i className='bi bi-x-circle-fill fs-1 clickable ms-4' onClick={deleteImage}></i>
                      </div>
                    </div>
                  </div>
                }

                <div className='card-body position-relative'>
                  <textarea
                    className='body-edit'
                    id='body'
                    name='body'
                    value={editPost.body}
                    onChange={handleChange}
                    onLoadedData={handleChange}
                    placeholder='Escribe aquí el texto de tu publicación' />
                </div>

                {!editPost.ext_link &&
                <div className=''>
                  <div className="input-group input-group-sm link-edit">
                    <input type="text" className="input-url" id="ext_link" name="ext_link" placeholder="Añade un enlace" />
                    <button className="btn btn-theme float-end" onClick={handleLinkChange}>+</button>
                  </div>
                </div>
                }

                {editPost.ext_link &&
                  <div style={{position: 'relative'}}>  
                  <LinkPreview
                    key={editPost.id}
                    url={editPost.ext_link}
                    apiLock={apiLock}
                    setApiLock={setApiLock}
                    disabled={true}
                  />
                  <div className='del-link'>
                    <i className='bi bi-x-circle-fill fs-1 clickable' onClick={deleteLink}></i>
                  </div>
                  </div>
                }

                <div className='post-footer'>
                  <span className='header-time ms-1'>{editPost.num_comments} comentario{editPost.num_comments == 1 ? '' : 's'}</span>    
                </div>
              </div>
            </div>}
          </Modal.Body>
          <Modal.Footer>
            <Button className="btn btn-danger btn-sm me-1" onClick={handleClose}>
              Cancelar
            </Button>&nbsp;
            <Button className="btn btn-theme btn-sm" onClick={savePost}>
              Publicar
            </Button>&nbsp;
          </Modal.Footer>
          
          {showLoader && <div className='loading'></div>}
        </Modal>

        <div className=' post-container ps-2 pe-2 mb-4'>
          {!fetched &&
            <div className='no-news'>
              <i className='bi bi-hourglass-split text-secondary fs-1'></i>
              <p className='text-secondary fs-4'>CARGANDO...</p>
            </div>
          }
          {(posts.length == 0 && fetched) &&
            <div className='no-news'>
              <i className='bi bi-bug text-secondary fs-1'></i>
              <p className='text-secondary fs-4'>{noPostMsg}</p>
              <hr />
              <p className='text-secondary fs-6'>
                Echa un vistazo a tus&nbsp;
                <span className='no-news-link clickable' onClick={()=>navigate(`${user.type == '0' ? '/userFarmer' : '/oneEngineer2'}`)}>invernaderos</span>
                &nbsp;o busca otros&nbsp;
                <span className='no-news-link clickable' onClick={()=>navigate('/allContacts')}>usuarios</span>
                &nbsp;con los que conectar.
              </p>
            </div>
          }

          {(posts.length > 0 && fetched) ?
            <>
            <div className='row'>
              <div className='col-12 text-center pt-4' ref={scrollRef}>
                <i onClick={doReload} title='Recargar' className='bi bi-arrow-clockwise fs-2 clickable'></i>
              </div>
            </div>
            {posts.map((post, index) => {
              return (
                <div className='col-12 mt-4' key={post.id}>
                  <div className='card'>
                    <div className='post-header'>
                      {post.user_id == user.id &&
                        <span className='float-start header-name'>
                        {!user.img && <img className={`user-img me-2`} src={`${config.MEDIA_URL}/images/profile/default.jpg`} />}
                        {user.img && <img className={`user-img me-2`} src={`${config.MEDIA_URL}/images/profile/${user.img}`} />}
                        Tú
                        </span>
                      }

                      {post.user_id != user.id &&
                        <span className='float-start header-name clickable' onClick={()=>navigate(`/chatwith/${post.user_id}`)} title='Ir a conversación'>
                        {!post.user_img && <img className={`user-img me-2`} src={`${config.MEDIA_URL}/images/profile/default.jpg`} />}
                        {post.user_img && <img className={`user-img me-2`} src={`${config.MEDIA_URL}/images/profile/${post.user_img}`} />}
                        {post.name} {post.lastname}
                        </span>
                      }

                      <span className='float-end header-time'>
                        {new Date(post.created_at).toLocaleString('es-Es', {dateStyle: 'short', timeStyle: 'short'})}
                        {post.user_id == user.id &&
                        <div id={post.id} className='post-opt' onClick={handleMenu} title='Opciones'>
                          <i className='bi bi-three-dots-vertical'></i>
                          <ul id={`menu${post.id}`} className='post-menu'>
                            <li key={index} id={post.id} onClick={handleEdit} className='dropdown-item'><i className='bi bi-pencil me-2'></i>Editar</li>
                            <li id={post.id} onClick={handleDelete} className='dropdown-item text-danger'><i className='bi bi-x-circle me-2'></i>Eliminar</li>
                          </ul>
                        </div>
                        }

                        {post.user_id != user.id &&
                        <span className='ms-2'>
                          {post.is_public == '0'
                            ? <i className='bi bi-people' title='Para sus contactos'></i>
                            : <i className='bi bi-globe' title='Para todos los usuarios'></i>
                          }
                        </span>
                        }
                      </span>
                    </div>
                    {post.image && post.filetype.startsWith('image') &&
                      <div className='post-image' style={{ backgroundImage: `url(${config.MEDIA_URL}/images/post/${post.image})`}}>
                        <div className='bg-blur'>
                          <img className='' src={`${config.MEDIA_URL}/images/post/${post.image}`} />
                        </div>
                      </div>
                    }
                    {post.image && post.filetype.startsWith('video') &&
                      <div className='post-image' style={{ backgroundColor: '#222222'}}>
                        <div className='bg-blur'>
                          <video autoPlay muted controls controlsList='nodownload' className='' src={`${config.MEDIA_URL}/images/post/${post.image}`} />
                        </div>
                      </div>
                    }

                    {post.body &&
                    <div className='card-body'>
                      <p className='post-body'>{post.body}</p>
                    </div>
                    }

                    {post.ext_link &&
                      <>
                      {!post.body && <div className='blank-space'></div>}
                      <LinkPreview
                        key={`${post.id}_${index}`}
                        url={post.ext_link}
                        apiLock={apiLock}
                        setApiLock={setApiLock}
                        disabled={false}
                      />
                      </>
                    }
                    <div className='post-footer'>
                      <div id={post.id} className='clickable' onClick={toggleComments}>
                        <span className='header-time'>{post.num_comments} comentario{post.num_comments == 1 ? '' : 's'}</span>
                        <span className='float-end'>
                          <i id={`${post.id}_arrow`} className='bi bi-caret-down-fill text-secondary transitioning'></i>
                        </span>
                      </div>
                      <div id={`${post.id}_panel`} className='post-comments'>
                      {showComments[post.id] &&
                        <Comments post={post.id} user={user} />
                      }
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
            </>
          : null}
        </div>
      </div>
    </main>
  );
};