import React from "react";
import { useNavigate } from "react-router-dom";
import "./greenComp.scss";
import config from "../config.json";

export const OtherGreenComp = ({ greenhouses, busqueda }) => {
  const navigate = useNavigate();

  function renderSwitchInfected(infected) {
    switch (infected) {
      case 1:
        return <><span className='text-red'>Infectado</span></>;
      case 0:
        return 'Sano';
      case 2:
        return "En tratamiento";
      default:
        return '';
    }
  }

  return (
    <div>
      <div className='row'>
        <div className='no-news show-alone'>
              <p className='text-secondary fs-4'>Sin coincidencias</p>
        </div>
      {greenhouses &&
        greenhouses
          .filter((invernadero) => {
            if (busqueda === "") {
              return invernadero;
            } else if (
              invernadero.name_greenhouse.toLowerCase().includes(busqueda.toLowerCase()) ||
              invernadero.name.toLowerCase().includes(busqueda.toLowerCase()) ||
              invernadero.lastname.toLowerCase().includes(busqueda.toLowerCase()) ||
              invernadero.email.toLowerCase().includes(busqueda.toLowerCase())
            )
              return invernadero;
          })
          .map((invernadero, index) => {
            let url = `/otherGreenhouse/${invernadero.greenhouse_id}`;
            let health = "bg-healthy";

            if (invernadero.infected == 1) {
              health = "bg-infected";
            }
            if (invernadero.infected == 2) {
              health = "bg-treatment";
            }

            return (  
              <div  className="col-12 col-md-6 col-lg-4" key={invernadero.greenhouse_id}>
                <div
                  className="card shadow-none mb-3"
                  onClick={() =>
                    navigate(url)
                  }
                >
                  <div className={`card-body clickable ${health}`}>
                    <div className="">
                      <span className="fw-bold">{invernadero.name_greenhouse}
                        <span className={`float-end py-1 m-0 text-white badge ${invernadero.role_id == 1 ? 'text-bg-success' : 'text-bg-danger'}`}>
                          {invernadero.role_id == 1 ? 'Consulta' : 'Control'}
                        </span>
                      </span>
                    </div>
                    <hr className="mt-2"/>
                    <div className="text-secondary small">
                      <div className={`row mb-2 profile-card`}>
                        <div className={`col-auto`}>
                          {!invernadero.user_img && <img className={`profile-img`} src={`${config.MEDIA_URL}/images/profile/default.jpg`} />}
                          {invernadero.user_img && <img className={`profile-img`} src={`${config.MEDIA_URL}/images/profile/${invernadero.user_img}`} />}
                        </div>
                        <div className='col px-0'>
                          <div className="fw-bold">
                            {invernadero.name} {invernadero.lastname}
                          </div>
                          <div className="">
                            {invernadero.email}
                          </div>
                        </div>
                      </div>
                      <div>
                      Cultivo <b>{invernadero.crop_name}</b> <br />
                      Salud: <b>{invernadero.health}</b><br />
                      Temperatura: <b>{invernadero.temperature}</b><br />
                      Estado: <b>{renderSwitchInfected(invernadero.infected)}</b>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })
        }
        </div>
    </div>
  );
};
