import React, { useEffect, useState } from "react";
import { greenhouseAPI } from '../api';
import { useNavigate } from "react-router-dom";
import Cookies from 'js-cookie';
import "./formCreateGreen.scss";

export const FormCreateGreenhouse = () => {
  const navigate = useNavigate();
  const [crops, setCrop] = useState();
  const token = Cookies.get("token");

  function Capitalize(str){
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  const [regGreenhouse, setRegGreenhouse] = useState({
    name_greenhouse: "",
    location: "",
    year: "",
    production: "",
    crop_id: "",
    irrigation_system: "",
    light_system: "",
    fertilize_system: "",
    phytosan_system: "",
    seed_brand: "",
    seed_lot: ""
  });

  //--------------traemos todos los tipos de cultivo
  useEffect(() => {
    greenhouseAPI
      .get(`/greenhouse/showAllCrops`)
      .then((res) => {
        if (res.data) {
          setCrop(res.data);
        }
      })
      .catch((err) => console.log(err));
  }, []);

  // Aqui le estamos poniendo condiciones para que no se puedan crear invernaderos con datos mayores a 2 digitos en los campos temp, co2, humedad del suelo, humedad del aire, calidad del suelo.
  const handleChange = (e) => {
    if (e.target.name === "year" && e.target.value.length > 4) {
      window.alert("EL año tiene que ser 4 digitos");
    } else {
      setRegGreenhouse({
        ...regGreenhouse,
        [e.target.name]: e.target.value,
      });
    }
  };

  //Capturar localización
  const handleLocation = (e) => {
    navigator.geolocation.getCurrentPosition((pos)=>{
      setRegGreenhouse({ ...regGreenhouse, location: pos.coords.latitude + ',' + pos.coords.longitude });
    });
  };

  const handleLabel = (e) => {
    e.target.nextSibling.innerHTML = e.target.title;
  };

  const handleLabelOut = (e) => {
    if (e.target.value.length == 0)
      e.target.nextSibling.innerHTML = e.target.placeholder;
  };

  //Estado para enviar los datos (cuando pulsamos el boton en este caso) a la bbdd y volver a la pagina de invernaderos
  const handleSubmit = (e) => {
    e.preventDefault();
    // Este If por vienen campos vacios que muestre el alert
    if (
      regGreenhouse.name_greenhouse === "" ||
      regGreenhouse.year === "" ||
      regGreenhouse.user_farmer_id === ""
    ) {
      window.alert("Rellene campos obligatorios");
    }
    else{
      regGreenhouse.production = regGreenhouse.crop_id;
      greenhouseAPI
        .post(
          `/greenhouse/createGreenhouse`,
          regGreenhouse,
          {headers: {authorization: token}}
        )
        .then((res) => {
          navigate(`/userFarmer`);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <main className='main mainheight'>
      <div className='container'>
        <div className='row'>
          <div className="col text-center">
            <p></p>
            <h4>Crear <span className="text-gradient-green-orange">invernadero</span></h4>
            <p className="text-secondary">Cree un invernadero y asocielo a un ingeniero</p>
          </div>

        </div>

        <h5 className='title'>Invernadero</h5>
        <div className="row">


          <div className="col-12 col-md-6 col-xl-4 mb-4 mb-md-0  was-validated">
            <div>
              <div className="form-group mb-3 position-relative check-valid is-valid">
                <div className="input-group input-group-lg">
                  <span className="input-group-text text-theme border-end-0"><i className="bi bi-card-text"></i></span>
                  <div className="form-floating">
                    <input
                      type="text"
                      name="name_greenhouse"
                      value={regGreenhouse.name_greenhouse}
                      onChange={handleChange}
                      placeholder="Nombre del invernadero"
                      required
                      className="form-control border-start-0" />
                    <label>Nombre</label>
                  </div>
                </div>
              </div>
              <div className="invalid-feedback mb-3">Añada dato válido</div>
            </div>
          </div>

          <div className="col-12 col-md-6 col-xl-4 mb-4 mb-md-0  no-check-valid">
            <div>
              <div className="form-group mb-3 position-relative check-valid is-valid">
                <div className="input-group input-group-lg">
                  <span className="input-group-text text-theme border-end-0"><i className="bi bi-globe"></i></span>
                  <div className="form-floating" onFocus={handleLabel} onBlur={handleLabelOut}>
                    <input
                      type="text"
                      name="location"
                      value={regGreenhouse.location}
                      onChange={handleChange}
                      className="form-control border-start-0"
                      placeholder="Ubicación: <span class='placeh'>Coordenadas (lat, lon)</span>"
                      title="Ubicación: <span class='placeh'>latitud, longitud</span>" />
                    <label>Ubicación: <span class='placeh'>Coordenadas (lat, lon)</span></label>
                  </div>
                  <button className='btn btn-theme float-end' onClick={handleLocation}>GPS</button>
                </div>
              </div>
              <div className="invalid-feedback mb-3">Añada dato válido</div>
            </div>
          </div>

          <div className="col-12 col-md-6 col-xl-4 mb-4 mb-md-0  was-validated">
            <div>
              <div className="form-group mb-3 position-relative check-valid is-valid">
                <div className="input-group input-group-lg">
                  <span className="input-group-text text-theme border-end-0"><i className="bi bi-calendar2"></i></span>
                  <div className="form-floating">
                    <input
                      type="text"
                      name="year"
                      value={regGreenhouse.year}
                      onChange={handleChange}
                      placeholder="Año"
                      required
                      className="form-control border-start-0" />
                    <label>Año</label>
                  </div>
                </div>
              </div>
              <div className="invalid-feedback mb-3">Añada dato válido</div>
            </div>
          </div>

          <div className="col-12 col-md-6 col-xl-4 mb-4 mb-md-0  was-validated">
            <div>
              <div className="form-group mb-3 position-relative check-valid is-valid">
                <div className="input-group input-group-lg">
                  <span className="input-group-text text-theme border-end-0"><i className="bi bi-card-text"></i></span>
                  <div className="form-floating">
                    <select
                      name="crop_id"
                      value={regGreenhouse.crop_id}
                      onChange={handleChange}
                      placeholder="Tipo Cultivo"
                      required
                      className="form-select border-0 border-start-0" >
                      <option value="">Seleccione...</option>

                      {crops ? crops.map((crop, index) => {
                        return (
                          <option key={index} value={crop.crop_id}>{Capitalize(crop.crop_name)}</option>
                        )
                      }) : null}
                    </select>
                    <label>Cultivo/s</label>
                  </div>
                </div>
              </div>
              <div className="invalid-feedback mb-3">Añada dato válido</div>
            </div>
          </div>
        </div>

        <h5 className='title'>Sistemas</h5>
        <div className="row">

          <div className="col-12 col-md-6 col-xl-4 mb-4 mb-md-0  no-was-validated">
            <div>
              <div className="form-group mb-3 position-relative no-check-valid ">
                <div className="input-group input-group-lg">
                  <span className="input-group-text text-theme border-end-0"><i className="bi bi-card-text"></i></span>
                  <div className="form-floating">
                    <select
                      className="form-select border-0 border-start-0"
                      name="irrigation_system"
                      value={regGreenhouse.irrigation_system}
                      onChange={handleChange}
                      placeholder="Riego: <span class='placeh'>goteo, aspersión...</span>"
                      title="Riego" >
                      <option value="">Seleccione...</option>
                      <option value="Goteo">Goteo</option>
                      <option value="Microaspersión">Microaspersión</option>
                      <option value="Difusores">Difusores</option>
                      <option value="Subterráneo">Subterráneo</option>
                      <option value="Con manguera">Con manguera</option>
                      <option value="Hidropónico">Hidropónico</option>
                      <option value="Nebulización">Nebulización</option>
                    </select>
                    <label>Riego</label>
                  </div>
                </div>
              </div>
              <div className="invalid-feedback mb-3">Añada dato válido</div>
            </div>
          </div>

          <div className="col-12 col-md-6 col-xl-4 mb-4 mb-md-0  no-was-validated">
            <div>
              <div className="form-group mb-3 position-relative no-check-valid ">
                <div className="input-group input-group-lg">
                  <span className="input-group-text text-theme border-end-0"><i className="bi bi-card-text"></i></span>
                  <div className="form-floating" onFocus={handleLabel} onBlur={handleLabelOut}>
                    <input
                      className="form-control border-start-0"
                      type="text"
                      name="light_system"
                      value={regGreenhouse.light_system}
                      onChange={handleChange}
                      placeholder="Iluminación: <span class='placeh'>solar, artificial...</span>"
                      title="Iluminación" />
                    <label>Iluminación: <span className="placeh">solar, artificial...</span></label>
                  </div>
                </div>
              </div>
              <div className="invalid-feedback mb-3">Añada dato válido</div>
            </div>
          </div>

          <div className="col-12 col-md-6 col-xl-4 mb-4 mb-md-0  no-was-validated">
            <div>
              <div className="form-group mb-3 position-relative no-check-valid ">
                <div className="input-group input-group-lg">
                  <span className="input-group-text text-theme border-end-0"><i className="bi bi-card-text"></i></span>
                  <div className="form-floating" onFocus={handleLabel} onBlur={handleLabelOut}>
                    <input
                      className="form-control border-start-0"
                      type="text"
                      name="fertilize_system"
                      value={regGreenhouse.fertilize_system}
                      onChange={handleChange}
                      placeholder="Fertilización: <span class='placeh'>fertirriego, manual...</span>"
                      title="Fertilización" />
                    <label>Fertilización: <span className="placeh">fertirriego, manual...</span></label>
                  </div>
                </div>
              </div>
              <div className="invalid-feedback mb-3">Añada dato válido</div>
            </div>
          </div>

          <div className="col-12 col-md-6 col-xl-4 mb-4 mb-md-0  no-was-validated">
            <div>
              <div className="form-group mb-3 position-relative no-check-valid ">
                <div className="input-group input-group-lg">
                  <span className="input-group-text text-theme border-end-0"><i className="bi bi-card-text"></i></span>
                  <div className="form-floating" onFocus={handleLabel} onBlur={handleLabelOut}>
                    <input
                      type="text"
                      name="phytosan_system"
                      value={regGreenhouse.phytosan_system}
                      onChange={handleChange}
                      placeholder="Fitosanitario: <span class='placeh'>Riego, Pistola, Nebulización...</span>"
                      className="form-control border-start-0"
                      title="Fitosanitario" />
                    <label>Fitosanitario: <span className='placeh'>Riego, Pistola, Nebulización...</span></label>
                  </div>
                </div>
              </div>
              <div className="invalid-feedback mb-3">Añada dato válido</div>
            </div>
          </div>


        </div>

        <h5 className='title'>Semilla</h5>
        <div className="row">

          <div className="col-12 col-md-6 col-xl-4 mb-4 mb-md-0  no-was-validated">
            <div>
              <div className="form-group mb-3 position-relative no-check-valid ">
                <div className="input-group input-group-lg">
                  <span className="input-group-text text-theme border-end-0"><i className="bi bi-card-text"></i></span>
                  <div className="form-floating">
                    <input
                      type="text"
                      name="seed_brand"
                      value={regGreenhouse.seed_brand}
                      onChange={handleChange}
                      placeholder="Marca semilla"
                      className="form-control border-start-0" />
                    <label>Marca</label>
                  </div>
                </div>
              </div>
              <div className="invalid-feedback mb-3">Añada dato válido</div>
            </div>
          </div>

          <div className="col-12 col-md-6 col-xl-4 mb-4 mb-md-0  no-was-validated">
            <div>
              <div className="form-group mb-3 position-relative no-check-valid ">
                <div className="input-group input-group-lg">
                  <span className="input-group-text text-theme border-end-0"><i className="bi bi-card-text"></i></span>
                  <div className="form-floating">
                    <input
                      type="text"
                      name="seed_lot"
                      value={regGreenhouse.seed_lot}
                      onChange={handleChange}
                      placeholder="Lote semilla"
                      className="form-control border-start-0" />
                    <label>Lote</label>
                  </div>
                </div>
              </div>
              <div className="invalid-feedback mb-3">Añada dato válido</div>
            </div>
          </div>
        </div>

        <div className='row accept-cancel'>
          <div className="col-auto mb-2">
            <div>
              <button className='btn btn-theme'
                onClick={handleSubmit}
                type="submit">Guardar</button>
            </div>
          </div>
          <div className="col-auto mb-2">
            <div>
              <button className='btn btn-danger botonCancCreateGreen'
                type='onSubmit'
                onClick={() => navigate(-1)}>Cancelar</button><br />
            </div>
          </div>
        </div>
        <br />


        {/* 
        <div className="regGreen">
          <form className="createInv">
            <div>
              <p>Invernadero</p>
              <hr />

              <label>
                Nombre
                <input
                  className="nombre inputGreen"
                  type="text"
                  name="name_greenhouse"
                  value={regGreenhouse.name_greenhouse}
                  onChange={handleChange}
                  placeholder="Nombre del invernadero"
                />
              </label>

              <label>
                Año
                <input
                  className="inputGreen anio"
                  type="text"
                  name="year"
                  value={regGreenhouse.year}
                  onChange={handleChange}
                  placeholder="YYYY"
                />
              </label>

              <label>
                Cultivo
                <input
                  className="inputGreen cultivo"
                  type="text"
                  name="growing"
                  value={regGreenhouse.growing}
                  onChange={handleChange}
                  placeholder="Cultivo/s"
                />
              </label>

              <p>Sistemas</p>
              <hr />

              <label>
                Riego
                <input
                  className="inputGreen"
                  type="text"
                  name="irrigation_system"
                  value={regGreenhouse.irrigation_system}
                  onChange={handleChange}
                  placeholder="Sistema de riego"
                />
              </label>

              <label>
                Ilumianción
                <input
                  className="inputGreen"
                  type="text"
                  name="light_system"
                  value={regGreenhouse.light_system}
                  onChange={handleChange}
                  placeholder="Sistema de iluminación"
                />
              </label>

              <label>
                Fertilización
                <input
                  className="inputGreen"
                  label="Sistema de fertilización"
                  type="text"
                  name="fertilize_system"
                  value={regGreenhouse.fertilize_system}
                  onChange={handleChange}
                  placeholder="Sistema de fertilizacion"
                />
              </label>

              <label>
                Fitosanitario
                <input
                  className="inputGreen"
                  type="text"
                  name="phytosan_system"
                  value={regGreenhouse.phytosan_system}
                  onChange={handleChange}
                  placeholder="Sistema fitosanitario"
                />
              </label>

              <p>Semilla</p>
              <hr />

              <label>
                Marca
                <br />
                semilla
                <input
                  className="inputGreen"
                  type="text"
                  name="seed_brand"
                  value={regGreenhouse.seed_brand}
                  onChange={handleChange}
                  placeholder="Marca semilla"
                />
              </label>

              <label>
                Lote
                <br />
                semilla
                <input
                  className="inputGreen"
                  type="text"
                  name="seed_lot"
                  value={regGreenhouse.seed_lot}
                  onChange={handleChange}
                  placeholder="Lote semilla"
                />
              </label>

              <p>Sensores</p>
              <hr />

              <label className="labelSalud">
                Salud
                <br />
                general
                <select
                  value={regGreenhouse.health}
                  name="health"
                  onChange={handleChange}
                  className="selectorSalud"
                >
                  <option className="elegirOpcion"></option>
                  <option value="Bueno">Bueno</option>
                  <option value="Problema Biológico">Problema Biológico</option>
                  <option value="Problema Agua">Problema de agua</option>
                  <option value="Problema Nutrientes">
                    Problema de nutrientes
                  </option>
                </select>
                <p></p>
              </label>

              <label>
                Temperatura&nbsp;&nbsp;
                <input
                  className="inputGreen"
                  type="number"
                  name="temperature"
                  value={regGreenhouse.temperature}
                  onChange={handleChange}
                  placeholder="Temperatura (ºC)"
                />
              </label>

              <label>
                Humedad
                <br />
                ambiente
                <input
                  className="inputGreen"
                  type="number"
                  name="humidity_air"
                  value={regGreenhouse.humidity_air}
                  onChange={handleChange}
                  placeholder="Humedad del ambiente (%)"
                />
              </label>

              <label>
                Co2
                <input
                  className="inputGreen"
                  type="number"
                  name="co2"
                  value={regGreenhouse.co2}
                  onChange={handleChange}
                  placeholder="Nivel de Co2 (ppm)"
                />
              </label>

              <label>
                Calidad
                <br />
                tierra
                <input
                  className="inputGreen"
                  type="text"
                  name="quality_soil"
                  value={regGreenhouse.quality_soil}
                  onChange={handleChange}
                  placeholder="Calida de la tierra"
                />
              </label>

              <label>
                Tipo
                <br />
                fertilizacion
                <input
                  className="inputGreen"
                  type="text"
                  name="fertilize_type"
                  value={regGreenhouse.fertilize_type}
                  onChange={handleChange}
                  placeholder="Tipo de fertilizacion"
                />
              </label>

              <label>
                Humedad
                <br />
                Tierra
                <input
                  className="inputGreen"
                  type="number"
                  name="humidity_soil"
                  value={regGreenhouse.humidity_soil}
                  onChange={handleChange}
                  placeholder="Humedad de la tierra (%)"
                />
              </label>

              <p>Seleccionar Ingeniero:</p>
              <hr />

              <select
                className="selectorIng"
                value={regGreenhouse.user_engineer_id}
                name="user_engineer_id"
                id=""
                onChange={handleChange}
                default
              >
                <option value="" />
                {engineer
                  ? engineer.map((eng, index) => {
                      return (
                        <option key={index} value={eng.user_id}>
                          {eng.name}
                        </option>
                      );
                    })
                  : null}
              </select>

              <br />
              {validation && <p>Hay campos vacios!</p>}

              <br />
              <div className="botonesGreen">
                <button
                  className="botGrenEdit"
                  type="onSubmit"
                  onClick={() => navigate(`/userFarmer/${user_id}`)}
                >
                  Cancelar
                </button>

                <button
                  className="botGrenEdit save"
                  onClick={handleSubmit}
                  type="submit"
                >
                  Guardar
                </button>
              </div>
            </div>
          </form>
        </div>*/}
        </div>
    </main>
  );
};
