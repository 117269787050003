import { userAPI } from '../../api';
import config from '../../config.json';
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Cookies from 'js-cookie';
import "./allcontactsFromUser.scss";

export const AllContacts = ( {user} ) => {
  const [busqueda, setBusqueda] = useState("");
  const [contacts, setContacts] = useState();
  const [filter, setFilter] = useState([0,1]);
  const [checkBtn, setCheckBtn] = useState(0);
  const navigate = useNavigate();
  const token = Cookies.get("token");
  // const setCheckFilters = (e) => {
  //   setCheckBtn(e.target.value);
  //   if (e.target.value == 0) {
  //     setFilter([0,1]);
  //   }
  //   else if (e.target.value == 1) {
  //     setFilter([0]);
  //   }
  //   else if (e.target.value == 2) {
  //     setFilter([1]);
  //   }
  // };

  const handleDelete = (e)=> {
    if (window.confirm('¿Seguro que quieres eliminar este contacto?')) {
      const form = {contact_id: e.target.id};

      userAPI
        .post(`/user/deleteContact`, form, {headers: {Authorization: token}})
        .then((res) => {
          navigate(0);
        
        })
        .catch((error) => {
          alert("Error");
        });
      }
  }

  useEffect(() => {
    userAPI
      .get(`/user/allContactsFromUser`, {headers: {authorization: token}})
      .then((res) => {
        if (res.data) {
          setContacts(res.data.result);
        }
      })
      .catch((error) => {
        console.log("Error");
      });
  }, []);

  return (
    <main className='main mainheight'>
      <div className='container'>

        <div className='row'>
          <div className="col text-center title-margin">
            <h4><span className="text-gradient-green-orange">Contactos</span></h4>
          </div>
        </div>

        <div className='row mb-4 keep-up'>
          <div className="col">
            <div className="card border-0 h-100">
              <div className="card-body">
                <div className="input-group input-group-md rounded mb-2">
                  <span className="input-group-text text-theme"><i className="bi bi-search"></i></span>
                  <input className="form-control"
                    type="search"
                    placeholder="Filtrar"
                    onChange={(e) => setBusqueda(e.target.value)} />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div className='row mb-3'>
          <div className="btn-group col text-center title-margin" onChange={setCheckFilters} >
            <input type="radio" className="btn-check" name="options-base" id="option5" value="0" checked={checkBtn == 0} />
            <label className="btn btn-outline-success" htmlFor="option5">
              <i className={`bi bi-people-fill ba-xl fs-5 me-2 ${checkBtn == 0 ? "text-light": "text-success"}`}></i>
              <span className='text-dark'>Todos</span>
            </label>

            <input type="radio" className="btn-check" name="options-base" id="option6" value="1" checked={checkBtn == 1} />
            <label className="btn btn-outline-success" htmlFor="option6">
              <i className={`bi bi-person-fill ba-xl fs-5 me-2 ${checkBtn == 1 ? "text-light": "text-warning"}`}></i>
              <span className='text-dark'>Agricultores</span>
            </label>

            <input type="radio" className="btn-check" name="options-base" id="option8" value="2" checked={checkBtn == 2} />
            <label className="btn btn-outline-success" htmlFor="option8">
              <i className={`bi bi-person-lines-fill ba-xl fs-5 me-2 ${checkBtn == 2 ? "text-light": "text-primary"}`}></i>
              <span className='text-dark'>Ingenieros</span>
            </label>
            </div>
        </div> */}

        <div className='row'>
          { (contacts && contacts.length > 0) ?
            // Hacemos un filtro en el .map de los elementos que coincidan con la busqueda
            contacts.filter((contact) => {
              if (busqueda === '') {
                return contact;
              } else if ((contact.name.toLowerCase() + ' ' + contact.lastname.toLowerCase()).includes(busqueda.toLowerCase())) {
                return contact;
              }
            }).map((obj, index) => {
              return (
                <div className="col-12 col-md-6 col-lg-4" key={obj.id}>
                  <div className="card shadow-none mb-3">
                    <div className="card-body">
                      <div className='row'>
                        <div className='col-auto'>
                          {!obj.user_img && <img className={`profile-img-all`} src={`${config.MEDIA_URL}/images/profile/default.jpg`} />}
                          {obj.user_img && <img className={`profile-img-all`} src={`${config.MEDIA_URL}/images/profile/${obj.user_img}`} />}
                        </div>
                        <div className='col ps-1 text-truncate'>
                          <h6 className="mb-2">
                          {/* {obj.type == "0" && <span><i className="bi bi-person-fill ba-xl fs-5 text-warning me-1"></i></span>}
                          {obj.type == "1" && <span><i className="bi bi-person-lines-fill ba-xl fs-5 text-primary me-1"></i></span>} */}
                          {obj.name} {obj.lastname}
                          </h6>
                          <ul className="text-secondary small mb-0 list-a">
                            <li>Email: <b>{obj.email}</b></li>
                            {obj.num_gh > 0 &&
                              <li>Invernaderos asignados: <b>{obj.num_gh > 0 ? obj.num_gh : 'Ninguno'}</b></li>
                            }
                            {obj.num_gh == 0 &&
                              <li>&nbsp;</li>
                            }
                          </ul>
                        </div>
                      </div>

                      <hr />
                      <div className="row align-items-center">
                        <div className="col text-center">
                          <Link to={`/chatwith/${obj.user_id}`} title='Enviar mensaje'>
                            <i className="bi bi-chat-text-fill ba-xl"></i>
                          </Link>
                        </div>
                        <div className="col text-center">
                          <a
                            href={`mailto:${obj.email}`}
                            target="_blank"
                            title='Enviar correo electrónico'
                          >
                            <i className="bi bi-envelope ba-xl"></i>
                          </a>
                        </div>
                        <div className="col text-center">

                        </div>
                        <div className="col text-center">

                        </div>
                        <div className="col text-center">
                          <a
                            href="#"
                            onClick={handleDelete}
                            title='Eliminar contacto'
                          >
                            <i id={obj.user_id} className="bi bi-x-circle ba-xl text-danger"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )

            }) : (
              <>
              <div className='col-3'></div>
              <div className='col-6 no-news'>
                <i className='bi bi-person-x text-secondary fs-1'></i>
                <p className='text-secondary fs-4'>No tienes contactos</p>
                <hr />
                <p className='text-secondary fs-6'>
                  <span className='no-news-link clickable' onClick={()=>navigate('/searchContacts')}>Busca otros usuarios</span>
                  &nbsp;con los que conectar.
                </p>
              </div>
              <div className='col-3'></div>
              </>
            )}
        </div>

        {/*
          <br /><br /><br />
          <div className='row'>
            <div className='col'>
              <div className="search mt-5">
                <input
                  className="buscar"
                  placeholder="Buscar..."
                  onChange={(e) => setBusqueda(e.target.value)}
                />
                <icon>
                  <VscSearch />
                </icon>
              </div>
            </div>
          </div>
          <div>
            {console.log(contacts)}
            {contacts
              ? // Hacemos un filtro en el .map de los elementos que coincidan con la busqueda
              contacts
                .filter((engineer) => {
                  if (busqueda === "") {
                    return engineer;
                  } else if (
                    engineer.name
                      .toLowerCase()
                      .includes(busqueda.toLowerCase()) ||
                    engineer.lastname
                      .toLowerCase()
                      .includes(busqueda.toLowerCase())
                  ) {
                    return engineer;
                  }
                })
                .map((eng, index) => {
                  return (
                    <div className="engineer" key={index}>
                      <div className="nombreDivEngi">
                        <h3 className="nombreEngi">
                          {eng.name} {eng.lastname}
                        </h3>
                      </div>
                      <div className="datos">
                        <div className="datoshijos">
                          <p>
                            <b>Tlf:</b> {eng.phone}
                            <a
                              href={`https://api.whatsapp.com/send?phone=${eng.phone}`}
                              target="_blank"
                            >
                              <img
                                className="imgWhatsapp"
                                src="/whatsapp.png"
                                alt="whatsapp"
                              />
                            </a>
                          </p>

                          <p>
                            <b>Email:</b> {eng.email}
                            <a href={`mailto:${eng.email}`} target="_blank">
                              <img
                                className="imgWhatsapp"
                                src="/gmail.png"
                                alt="gmail"
                              />
                            </a>
                          </p>
                        </div>
                      </div>
                    </div>
                  );
                })
              : null}
          </div>
      */}

      </div>
    </main>
  );
};
