import { messengerAPI } from '../../api';
import config from '../../config.json';
import React from "react";
import { useState } from "react";
import { useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Cookies from 'js-cookie';
import "./messaging.scss";

export const Messaging = ({ user, setMessg }) => {
  const { pair_id } = useParams();
  const [pairInfo, setPairInfo] = useState({});
  const [messages, setMessages] = useState([]);
  const [scrollToEnd, setScrollToEnd] = useState(false);
  const [timer, setTimer] = useState(false);
  const [newMessage, setNewMessage] = useState({
    id: null,
    sender_id: user,
    recip_id: Number(pair_id),
    body: '',
    answer_to_id: null,
    time: null
  });
  const navigate = useNavigate();
  const messagesEnd = useRef(null);

  const token = Cookies.get("token");

  useEffect(() => {
    messengerAPI.get(`/messenger/getPairInfo/${pair_id}`, {headers: {Authorization: token}})
      .then((res) => {
        setPairInfo(res.data.result[0]);
      })
      .catch((error) => {
        navigate('/');
      });
  }, []);

  useEffect(() => {
    messengerAPI.get(`/messenger/getMessages/${pair_id}`, {headers: {Authorization: token}})
      .then((res) => {
        setMessages(res.data.result);
        setScrollToEnd(!scrollToEnd);
        setMessg(0);
        setTimer(!timer);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [pairInfo]);

  useEffect(() => {
    //window.scrollTo(0, document.body.scrollHeight, {behavior: 'smooth'});
    messagesEnd.current.scrollIntoView(false);
  }, [scrollToEnd]);

  const handleChange = (e) => {
    setNewMessage({...newMessage, body: e.target.value});
  };
  
  const sendMessage = (e) => {
    if (newMessage.body != '') {
      newMessage.time = new Date().getTime();
      messengerAPI.post(`/messenger/sendMessage`, newMessage, {headers: {Authorization: token}})
        .then((res) => {
          setMessages([...messages, {...newMessage, id: res.data.result[0].insertId}]);
          setNewMessage({...newMessage, body: ''});
          setScrollToEnd(!scrollToEnd);
        })
        .catch((error) => {
          console.log(error);
          alert('Error al enviar el mensaje.');
        });

    }
  };

  const getNewMessages = (e) => {
    const last_id = messages.length > 0 ? messages[messages.length - 1].id : null;
    
    setTimer(!timer);
    messengerAPI.get(`/messenger/getMessages/${pair_id}/${last_id}`, {headers: {Authorization: token}})
      .then((res) => {
        if (res.data.result.length > 0) {
          setMessages([...messages, ...res.data.result]);
          setScrollToEnd(!scrollToEnd);
          setMessg(0);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    const timer = setTimeout(getNewMessages, 3000);
    return () => clearTimeout(timer);
  }, [timer]);

  const deleteMessage = (e) => {
    if (window.confirm('¿Seguro que quieres eliminar este mensaje?\nSólo se eliminará para ti.')) {
      messengerAPI.post(`/messenger/deleteMessage/${e.target.id}`, {}, {headers: {Authorization: token}})
        .then((res) => {
          setMessages(messages.filter((message) => message.id != e.target.id));
        })
        .catch((error) => {
          alert('Error al eliminar el mensaje.');
        });
    }
  };

  const backToList = () => {
    navigate('/chatlist')
  };

  return (
    <main className='main mainheight'>
      <div className='chat-header'>
        <div className='row'>
          <div className='col-auto align-middle'>
            {!pairInfo.user_img && <img className={`profile-img`} src={`${config.MEDIA_URL}/images/profile/default.jpg`} />}
            {pairInfo.user_img && <img className={`profile-img`} src={`${config.MEDIA_URL}/images/profile/${pairInfo.user_img}`} />}
          </div>
          <div className='col text-start ps-1'>
            <span className='small-text'>Mensajes con</span>
            <h5>{pairInfo.name} {pairInfo.lastname}</h5>
          </div>
        </div>
      </div>
        <div className='chat-body'>
          <div className='msg-list'>
          <div className='chat-top'></div>
          {messages.map((message, index) => {
            return (
              <div className='row' key={index}>
                {user == message.sender_id &&
                  <>
                  <div className='col-12'>
                    <p className='my message text-end float-end'>
                      <span className='message-time'>
                        {new Date(message.time).toLocaleString('es-Es', {dateStyle: 'short', timeStyle: 'short'})}
                        <i id={message.id} className="bi bi-x-circle text-danger ms-2 clickable" onClick={deleteMessage}></i>
                      </span>
                      <div className='message-body float-end'>{message.body}</div>
                    </p>
                  </div>
                  </>
                }

                {pair_id == message.sender_id && 
                  <>
                  <div className='col-12'>
                    <p className='his message text-start float-start'>
                      <span className='message-time'>
                        <i id={message.id} className="bi bi-x-circle text-danger me-2 clickable" onClick={deleteMessage}></i>
                        {new Date(message.time).toLocaleString('es-Es', {dateStyle: 'short', timeStyle: 'short'})}
                      </span>
                      <span className='message-body'>{message.body}</span>
                    </p>
                  </div>
                  </>
                }
              </div>
            );
          })}
          <div className='chat-bottom' ref={messagesEnd}></div>
          </div>
          <div className='message-input'>
            <div className='row'>
              <textarea className='col' placeholder='Escribe tu mensaje...' value={newMessage.body} onChange={handleChange}></textarea>
              <button className='col-auto' onClick={sendMessage}><i className="bi bi-envelope-plus fs-4 text-success"></i></button>
            </div>
          </div>
        </div>
    </main>
  );
};