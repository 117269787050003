import { userAPI } from '../../api';
import config from '../../config.json';
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import Cookies from 'js-cookie';
import "./allcontactsFromUser.scss";

export const SearchContacts = () => {
  const [query, setQuery] = useState("");
  const [lastQuery, setLastQuery] = useState("");
  const [invitationMail, setInvitationMail] = useState("");
  const [contacts, setContacts] = useState();
  const [filter, setFilter] = useState([0,1]);
  const [checkBtn, setCheckBtn] = useState(0);
  const [respMsg, setRespMsg] = useState("");
  const [sendOk, setSendOk] = useState(false);
  const [sendError, setSendError] = useState(false);
  const token = Cookies.get("token");

  // const setCheckFilters = (e) => {
  //   setCheckBtn(e.target.value);
  //   if (e.target.value == 0) {
  //     setFilter([0,1]);
  //   }
  //   else if (e.target.value == 1) {
  //     setFilter([0]);
  //   }
  //   else if (e.target.value == 2) {
  //     setFilter([1]);
  //   }
  // };

  const handleInvitation = (e) => {
    const form = {
      email: invitationMail,
      origin: `${window.location.protocol}//${window.location.host}`
    };

    setSendError(false);
    setSendOk(false);

    userAPI
      .post(`/user/sendInvitation`, form, {headers: {Authorization: token}})
      .then((res) => {
        if (res.status === 200)
          setRespMsg("Mail ya en tu lista de contactos/solicitudes");
        else {
          setRespMsg("¡Solicitud enviada!");
          setQuery(lastQuery);
          handleSearch({});
        }
        setSendOk(true);
      })
      .catch((error) => {
        if (error.response.status === 403)
          setRespMsg("No te puedes auto-invitar.");
        else if (error.code === 'ERR_BAD_REQUEST' && error.response.data.error.errno === 1062)
          setRespMsg("Ya has enviado una invitación a ese mail");
        else
          setRespMsg("Error al enviar la solicitud");
        setSendError(true);
      });
  };

  const handleDelete = (e)=> {
    if (window.confirm('¿Seguro que quieres eliminar esta solicitud?')) {
      const form = {contact_id: e.target.id};

      userAPI
        .post(`/user/deleteContact`, form, {headers: {Authorization: token}})
        .then((res) => {
          setQuery(lastQuery);
          handleSearch({});
        })
        .catch((error) => {
          alert("Error");
        });
      }
  }

  const handleContactRequest = (e)=> {
    const form = {contact_id: e.target.id};
    userAPI
      .post(`/user/contactRequest`, form, {headers: {Authorization: token}})
      .then((res) => {
        setQuery(lastQuery);
        handleSearch({});
      })
      .catch((error) => {
        alert("Error");
      });
  }

  const handleSearch = (e) => {
    if (query.length > 3 || e) {
      userAPI
        .get(`/user/searchContacts/${query}`, {headers: {Authorization: token}})
        .then((res) => {
          if (res.data) {
            setLastQuery(query);
            setContacts(res.data.result);
          }
        })
        .catch((error) => {
          console.log("Error");
      });
    }
  };

  useEffect(handleSearch, [query, token]);

  return (
    <main className='main mainheight'>
      <div className='container'>

        <div className='row'>
          <div className="col text-center title-margin">
            <h4><span className="text-gradient-green-orange">Añadir contacto</span></h4>
          </div>
        </div>

        <div className='row mb-4'>
          <div className="col">
            <div className="card border-0 h-100">
              <div className="card-body">
                <div className="input-group input-group-md rounded mb-2">
                  <span className="input-group-text text-theme"><i className="bi bi-search"></i></span>
                  <input className="form-control"
                    type="search"
                    placeholder="Escribe para empezar a buscar..."
                    value={query}
                    onChange={(e)=>setQuery(e.target.value)} 
                    onKeyUp={(e)=>{
                      if (e.key === "Enter")
                        handleSearch(e);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div className='row mb-3'>
          <div className="btn-group col text-center title-margin" onChange={setCheckFilters} >
            <input type="radio" className="btn-check" name="options-base" id="option5" value="0" checked={checkBtn == 0} />
            <label className="btn btn-outline-success" htmlFor="option5">
              <i className={`bi bi-people-fill ba-xl fs-5 me-2 ${checkBtn == 0 ? "text-light": "text-success"}`}></i>
              <span className='text-dark'>Todos</span>
            </label>

            <input type="radio" className="btn-check" name="options-base" id="option6" value="1" checked={checkBtn == 1} />
            <label className="btn btn-outline-success" htmlFor="option6">
              <i className={`bi bi-person-fill ba-xl fs-5 me-2 ${checkBtn == 1 ? "text-light": "text-warning"}`}></i>
              <span className='text-dark'>Agricultores</span>
            </label>

            <input type="radio" className="btn-check" name="options-base" id="option8" value="2" checked={checkBtn == 2} />
            <label className="btn btn-outline-success" htmlFor="option8">
              <i className={`bi bi-person-lines-fill ba-xl fs-5 me-2 ${checkBtn == 2 ? "text-light": "text-primary"}`}></i>
              <span className='text-dark'>Ingenieros</span>
            </label>
            </div>
        </div> */}

        <div className='row'>
          {contacts ?
            // Hacemos un filtro en el .map de los elementos que coincidan con la busqueda
            contacts.map((obj, index) => {
              let bgClass = "";
              let ptClass = "pt-2";
              
              if (obj.status == '0') {
                bgClass = "bg-contact";
                ptClass = "pt-3";
              }
              else if (obj.status == '1') {
                bgClass = "bg-invited";
                ptClass = "pt-3";
              }
              return (
                <div className="col-12 col-md-6 col-lg-4" key={obj.id}>
                  <div className={`card shadow-none mb-3 ${bgClass}`}>
                    <div className="row card-body input-group">
                      <div className='col-auto'>
                        {!obj.user_img && <img className={`profile-img`} src={`${config.MEDIA_URL}/images/profile/default.jpg`} />}
                        {obj.user_img && <img className={`profile-img`} src={`${config.MEDIA_URL}/images/profile/${obj.user_img}`} />}
                      </div>
                      <div className="col ps-1 text-truncate">
                      <h6 className="mb-1">
                      {obj.name} {obj.lastname}
                      </h6>
                      <p className="text-secondary small m-0 p-0">
                        <b>{obj.email}</b>
                      </p>
                      {obj.status == '0' && <span className='text-secondary fw-normal fst-italic'>Contacto</span>}
                      {obj.status == '1' && <span className='text-secondary fw-normal fst-italic'>Solicitud enviada</span>}
                      </div>
                      <div className={`col-1 text-center ${ptClass}`}>
                        {obj.status == null &&
                          <a href="#" onClick={handleContactRequest} title='Añadir contacto'>
                            <i id={obj.user_id} className="bi bi-plus-circle ba-xl text-success"></i>
                          </a>
                        }
                        {obj.status == '0' &&
                            <i id={obj.user_id} className="bi bi-check-circle ba-xl text-success"></i>
                        }
                        {obj.status == '1' &&
                          <a href="#" onClick={handleDelete} title='Cancelar solicitud'>
                            <i id={obj.user_id} className="bi bi-x-circle ba-xl text-danger"></i>
                          </a>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              )

            }) : null}
        </div>

        <div className='row mt-4'>
          <div className='col-md-2 col-lg-2'></div>
            <div className='col-12 col-md-8 col-lg-8'>
              <div className='text-center pt-4 pb-4'>
                <h4 className='text-secondary'>¡Invita a tus amigos!</h4>
                  <div className="input-group input-group-lg mb-3 mt-3 border border-success">
                      <span className="input-group-text text-theme border-end-0"><i className="bi bi-envelope"></i></span>
                      <div className="form-floating">
                          <input
                              type="text"
                              name="email"
                              value={invitationMail}
                              onChange={(e)=>{setInvitationMail(e.target.value)}}
                              className="form-control border-start-0"
                              placeholder="Email" />
                          <label>Email</label>
                      </div>
                      <button className='btn btn-theme float-end' onClick={handleInvitation}>Enviar</button>
                  </div>
                  {sendOk && <div className='alert alert-success text-center'>{respMsg}</div>}
                  {sendError && <div className='alert alert-danger text-center'>{respMsg}</div>}
              </div>
            </div>
          <div className='col-md-2 col-lg-2'></div>
        </div>

      </div>
    </main>
  );
};
