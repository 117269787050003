import React from "react";
import { Link, useNavigate } from "react-router-dom";

import "../error/error.scss";

let current_hostname = window.location.hostname;
let current_protocol = window.location.protocol;
let current_port = window.location.port;
if (current_hostname!='localhost'){
  current_protocol='https:';
  current_port = 443;
}
let current_root_url = current_protocol+'//'+current_hostname+':'+current_port+'/'

export const Error = () => {
  const navigate = useNavigate();

  return (
    <main className='main mainheight'>
      <div className='container'>
        <div className='row'>
          <div className="col text-center">
              <p>Lo sentimos, no hemos podido encontrar la página que buscas</p>
              <Link to={current_root_url}>
                <p className="perr3">Volver a la página de inicio</p>
              </Link>
          </div>
        </div>
      </div>
    </main>
  );
};
