import axios from 'axios';
import config from '../config.json';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './linkPreview.scss'

export const LinkPreview = ({ url, apiLock, setApiLock, disabled }) => {
  const [preview, setPreview] = useState({});

  const api = axios.create({ baseURL: config.LINK_API });
  api.defaults.baseURL = config.LINK_API;

  useEffect(() => {
    const waitApiLock = () => new Promise(async (resolve) => {
      while (apiLock) {
        await new Promise(resolve => setTimeout(resolve, 100));
      }
      resolve();
    });
    
    waitApiLock().then(() => 
      {
        setApiLock(true);
        api.post('/', { url: url })
        .then(res => {
          setPreview(res.data);
          setApiLock(false);
        })
        .catch(err => {
          console.log(err);
        });
      });
  }, []);

  return (
     disabled == 0
      ? (<div className='link-preview'>
        <Link to={url} target='_blank' rel='noopener noreferer'>
          <div className='row link-header'>
            {preview.image &&
              <div className='col-auto m-0 p-0'>
                <img className='link-image' src={preview.image} />
              </div>
            }
            <div className='col'>
            {preview.title &&
              <p className='link-title'>
                {preview.favicon && <span><img className='link-favicon' src={preview.favicon} /></span>}
                {preview.title}
              </p>
            }
            </div>
          </div>
          <div className='col'>
            {preview.description &&
              <p className='link-description'>{preview.description}</p>
            }
            <p className='link-url'>{url}</p>
          </div>
        </Link>
        </div>)
      : (<div className='link-preview'>
          <div className='row link-header'>
            {preview.image &&
              <div className='col-auto m-0 p-0'>
                <img className='link-image' src={preview.image} />
              </div>
            }
            <div className='col'>
            {preview.title &&
              <p className='link-title'>
                {preview.favicon && <span><img className='link-favicon' src={preview.favicon} /></span>}
                {preview.title}
              </p>
            }
            </div>
          </div>
          <div className='col'>
            {preview.description &&
              <p className='link-description'>{preview.description}</p>
            }
            <p className='link-url'>{url}</p>
          </div>
        </div>)
  );
};

