import React, { useEffect, useState } from "react";
import { Navbar, Nav } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import jwtDecode from "jwt-decode";
import Cookies from 'js-cookie';
import $ from "jquery";
//import "./navbar.scss";
//import "../assets/scss/style.css";
import "../assets/scss/_header.scss";

export const NavBar = ({ isLogged, setIsLogged, user, token, notif, messg }) => {
  const navigate = useNavigate();
  const [userId, setUserId] = useState();
  const [userType, setUserType] = useState();
  const [eventedMenuButton, setEventedMenuButton] = useState();

  const logOut = () => {
    Cookies.remove("token");
    navigate("/");
    setIsLogged(false);
    setEventedMenuButton(false);
  };

  useEffect(() => 
  {
    if (token) {
      setUserId(jwtDecode(token).user.id);
      setUserType(jwtDecode(token).user.type);
      setEventedMenuButton(false);
    }
  }, [isLogged, token]);

  function isHidden(el) {
    var style = window.getComputedStyle(el);
    return (style.display === 'none')
}

  function isVisible(el) {
    if (el){
      return (!isHidden(el));
    }else{
      return false;
    }
  }

  var isSafari = /^((?!chrome|android|gecko).)*safari/i.test(navigator.userAgent);

  function menu_btn_click(e){
    e.stopPropagation();
    //console.log('menu button click');
    //console.log('menu-close before:' + document.body.classList.contains('menu-close'));

    if (document.body.classList.contains('menu-close') === true) {
      document.body.classList.remove('menu-close');
    } else {
      document.body.classList.add('menu-close');
    }
    //console.log('menu-close after:' + document.body.classList.contains('menu-close'));
    return false;
  }

  function menuclosetablet() {
    //console.log('isSafari:' + isSafari);
    if (window.innerWidth <= 992) {
        //console.log('menuclosetablet mobile');
        //console.log('eventedMenuButton: '+eventedMenuButton);
        document.body.classList.add('menu-close');
        if (!eventedMenuButton){
          //console.log('adding body event');
          document.body.addEventListener("click", function (e) {
              if (!document.querySelector('.sidebar-wrap') || !isVisible(document.querySelector('.sidebar-wrap'))) {
                if(!document.body.classList.contains('menu-close')){
                  document.body.classList.add('menu-close');
                }
              }
          });
          setEventedMenuButton(true);
        }
    }
    else {
      //console.log('menuclosetablet desktop');
      if(document.body.classList.contains('menu-close')){
        document.body.classList.remove('menu-close');
      }
    }
  }
  menuclosetablet();

  function innermenuopen() {
    $('.innersidebar-btn').on('click', function () {
        if (document.body.classList.contains('innermenu-close') === true) {
            document.body.classList.remove('innermenu-close');
        } else {
            document.body.classList.add('innermenu-close');
        }
        return false;
    });
  }
  function innermenuclose() {
      $('.inner-sidebar-content').on('click', function () {
          if (document.body.classList.contains('innermenu-close') === true) {
              document.body.classList.remove('innermenu-close');
          }
      });
  }

  innermenuopen();

  if (window.innerWidth < 1300) {
      innermenuclose();
  }

  var footer = document.querySelector('.footer');
  var header = document.querySelector('.header > .navbar');
  var rightbarwrap = document.querySelector('.rightbar-wrap');
  var sidebarwrap = document.querySelector('.sidebar-wrap');
  var maincontentheight = window.outerHeight;

  //console.log(header);
  //console.log(isVisible(header));

  //console.log(sidebarwrap);
  //console.log(isVisible(sidebarwrap));

  if (header && isVisible(header)) {
    //console.log('header visible');  
    if (footer && isVisible(footer) > 0) {
        if ($('.footer-sticky').length > 0) {
            //console.log('footer-sticky case');
            setTimeout(function () {
                $('.mainheight').css({ 'min-height': maincontentheight - header.getBoundingClientRect().height - footer.outerHeight, 'margin-top': header.getBoundingClientRect().height, 'padding-bottom': 'env(safe-area-inset-bottom)' });
                footer.css({ 'padding-bottom': 'calc( env(safe-area-inset-bottom)' + ($('.footer-sticky').height() + 20) + 'px)' });
            }, 110);
        } else {
          //console.log('not footer-sticky case');
            setTimeout(function () {
                var mainheight = document.querySelector('mainheight');
                mainheight.css({ 'min-height': maincontentheight - header.getBoundingClientRect().height - footer.outerHeight, 'margin-top': header.getBoundingClientRect().height, 'padding-top': 'env(safe-area-inset-top)', 'padding-bottom': 'env(safe-area-inset-bottom)' });
            }, 100);
        }
        if (rightbarwrap && isVisible(rightbarwrap)) {
            //console.log('rightbarwrap case');
            rightbarwrap.css({ 'padding-bottom': footer.outerHeight });
        }
    } else {
        //console.log('navbar: no footer case');

        setTimeout(function () {
            var mainheight = document.querySelector('.mainheight');
            if (mainheight){
            mainheight.style.minHeight = maincontentheight - header.getBoundingClientRect().height + "px";
            mainheight.style.marginTop = header.getBoundingClientRect().height + "px";
            mainheight.style.paddingTop = 'env(safe-area-inset-top)';
            mainheight.style.paddingBottom = 'env(safe-area-inset-bottom)';}
          }, 100);
    }

    if (sidebarwrap && isVisible(sidebarwrap)) {
        document.querySelector('.sidebar-wrap').style.paddingTop = 'calc(env(safe-area-inset-top) + ' + (header.getBoundingClientRect().height + 20) + 'px)';
    }
    if (rightbarwrap && isVisible(rightbarwrap)) {
        rightbarwrap.css({ 'padding-top': 'calc(env(safe-area-inset-top) + ' + header.getBoundingClientRect().height + 'px)' });
    }
  }

  function hideBackButton() {
    var butt = document.getElementById('back-button');
    if (butt) {
      var path = window.location.pathname;
      if (path.startsWith('/userFarmer') || path.startsWith('/oneEngineer2') || path.startsWith('/admin')) {
        butt.style.visibility = 'hidden';
      }
      else {
        butt.style.visibility = 'visible';
      }
    }
  }
  
  hideBackButton();

  return (
    <>
      {isLogged && user ? (
        <header className='header'>
        <nav className="navbar fixed-top" >

          <div className="container-fluid">
            
            {isLogged && (
              <>
                <button className="btn btn-link btn-square menu-btn me-2" type="button" onClick={menu_btn_click}>
                  <i className="bi bi-list fs-4"></i>
                  {(notif > 0 || messg > 0) && <i className="position-absolute bi bi-circle-fill fs-10 ms-0 text-danger"></i>}
                </button>
              </>)
            }
            
            {isLogged && userType === 0 && (
            <Navbar.Brand as={Link} to={`/userFarmer`} className="margin-0">
              <div className="logoNav">
                <img className="imgLogoNav" src="/GRODI_Color_200px.png" alt="logo" />
              </div>
            </Navbar.Brand>
            )}
            
            {isLogged && userType === 1 && (
            <Navbar.Brand as={Link} to={`/oneEngineer2`} className="margin-0">
              <div className="logoNav">
                <img className="imgLogoNav" src="/GRODI_Color_200px.png" alt="logo" />
              </div>
            </Navbar.Brand>
            )}
            
            {isLogged && userType === 2 && ( 
            <Navbar.Brand as={Link} to={`/admin`} className="margin-0">
              <div className="logoNav">
                <img className="imgLogoNav" src="/GRODI_Color_200px.png" alt="logo" />
              </div>
            </Navbar.Brand>
            )}
            
            {isLogged && (
              <>
                <button id="back-button" className="btn btn-link btn-square menu-btn float-end" type="button" onClick={()=>{navigate(-1)}}>
                  <i className="bi bi-arrow-bar-left fs-4"></i>
                </button>
              </>)
            }
          </div>
        </nav>
        </header>
      ) : null}
    </>
  );
};
