import React, { useEffect, useState } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { UserFarmer } from "../pages/user/UserFarmer";
import { Greenhouse } from "../pages/greenhouse/Greenhouse";
import { OtherGreenhouse } from "../pages/greenhouse/OtherGreenhouse";
import { EditUser } from "../pages/user/EditUser";
import { WeatherAPI } from "../components/weatherAPI/WeatherAPI";
import { FormCreateGreenhouse } from "../components/FormCreateGreenhouse";
import { FormEditGreenhouse } from "../components/FormEditGreenhouse";
import { NavBar } from "../components/NavBar";
import { SideBar } from "../components/SideBar";
import jwtDecode from "jwt-decode";
import { Error } from "../pages/error/Error";
import { AllContacts } from "../pages/user/allContacts";
import { SearchContacts } from "../pages/user/searchContacts";
import { Notifications } from "../pages/user/notifications";
import { Messaging } from "../pages/user/messaging";
import { Chatlist} from "../pages/user/chatlist";
import { Board } from "../pages/board/board";
import { greenhouseAPI, userAPI } from '../api';
import { Redirect } from "../pages/auth/Redirect";
import Cookies from "js-cookie";
import config from "../config";

export const AppRoutes = ({ isLogged, setIsLogged }) => {
  const [notif, setNotif] = useState(0);
  const [messg, setMessg] = useState(0);
  const [modalPost, setModalPost] = useState(false);
  const [user_id, setUser_id] = useState();
  const [userType, setUserType] = useState();
  const [inverAfectado, setInverAfectado] = useState({
    estadoInvernadero: "",
    nombrePlaga: "",
  });
  const [profileChange, setProfileChange] = useState(false);
  const [user, setUser] = useState({
    name: "",
    img: "",
  });

  const location = useLocation();
  const token = Cookies.get("token");

  useEffect(() => {
    if (token) {
      setUser_id(jwtDecode(token).user.id);
      setUserType(jwtDecode(token).user.type);
    }
  }, [token]);

  useEffect(() => {
    if (token) {
      // Check expiration date
      const expdate = jwtDecode(token).exp;
      if (expdate * 1000 < Date.now()) {
        Cookies.remove("token", { domain: '.groditech.com', expires: 7 });
        window.location.reload();
      }
      else if (((expdate * 1000) - Date.now()) < 518400000) {
        // Extend expiration date
        userAPI
          .get( "/user/tokenupdate", {headers: {Authorization: token}})
          .then((res) => {
            token = res.data.token;
            setIsLogged(true);
            Cookies.set("token", token, { domain: '.groditech.com', expires: 7 });
          })
          .catch((error) => {
            Cookies.remove("token", { domain: '.groditech.com', expires: 7 });
            window.location.reload();
          });
      }
    }
  }, [isLogged, token]);

  useEffect(() => {
    if (token) {
      userAPI
        .get(`/user/oneUser`, {headers: {authorization: token}})
        .then((res) => {
          setUser(res.data.resultFarmer[0]);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [user_id, profileChange]);

  useEffect(() => {
    if (user_id != null && location.pathname !== '/login') {
      userAPI
        .get(`/user/unreadNotif`, {headers: {authorization: token}})
        .then((res) => {
          setNotif(res.data.result[0].unread_notif);
          setMessg(res.data.result[0].unread_messg);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [user_id, location, notif, messg]);

  useEffect(() => {
    if (token) {
      userAPI
        .post(`/user/checkUserApp`, {app_id: 1}, {headers: {authorization: token}})
        .then((res) => {
          if (res.status == 201) {
            greenhouseAPI
              .post(`/greenhouse/createDemoGreenhouse`, {}, {headers: {authorization: token}})
              .then((res) => {
                console.log('Demo greenhouse created');
              })
              .catch((error) => {
                console.log('ERROR: createDemoGreenhouse');
              });
          }
        })
        .catch((error) => {
          console.log('ERROR: checkUserApp');
        });
    }
  }, [token]);

  return (
    <>
        <NavBar
          isLogged={isLogged}
          setIsLogged={setIsLogged}
          user={user}
          token={token}
          notif={notif}
          messg={messg}
        />
        <SideBar 
          isLogged={isLogged}
          setIsLogged={setIsLogged}
          user={user}
          token={token}
          notif={notif}
          mediaURL={config.MEDIA_URL}
          messg={messg}
          setModalPost={setModalPost}
        />

        <Routes>
          {!token && (
            <>
              <Route path="*" element={<Redirect url={`${config.AUTH_URL}/login?backurl=${window.location.protocol}//${window.location.host}`} />} />
            </>
          )}
          {token && (
            <>
              <Route path="/" element={<Navigate to={"/userFarmer"} />} />
              <Route
                path="/greenhouse/:greenhouse_id"
                element={
                  <Greenhouse
                    inverAfectado={inverAfectado}
                    setInverAfectado={setInverAfectado}
                  />
                }
              />
              <Route
                path="/otherGreenhouse/:greenhouse_id"
                element={
                  <OtherGreenhouse
                    inverAfectado={inverAfectado}
                    setInverAfectado={setInverAfectado}
                  />
                }
              />
              <Route
                path="/userFarmer"
                element={
                  <UserFarmer
                    inverAfectado={inverAfectado}
                    setInverAfectado={setInverAfectado}
                  />
                }
              />

              <Route path="/weatherAPI" element={<WeatherAPI />} />
              <Route
                path="/createGreenhouse"
                element={<FormCreateGreenhouse />}
              />
              <Route
                path="/editGreenhouse/:greenhouse_id"
                element={<FormEditGreenhouse />}
              />
              {/* <Route path="/createEngineer" element={<FormCreateEngineer />} /> */}

            <Route
              path="/editUser"
              element={
                <EditUser
                  profileChange={profileChange}
                  setProfileChange={setProfileChange}
                  user={user}
                  setUser={setUser}
                />
              }
            />
            <Route path="/board" element={<Board modalPost={modalPost} setModalPost={setModalPost}/>} />
            <Route path="/allContacts" element={<AllContacts user={user} />} />
            <Route path="/searchContacts" element={<SearchContacts />} />
            <Route path="/chatlist" element={<Chatlist setNotif={setNotif}/>} />
            <Route path="/notifications" element={<Notifications setNotif={setNotif}/>} />
            <Route path="/chatwith/:pair_id" element={<Messaging user={user_id}  setMessg={setMessg}/>} />
            <Route path="/logout" element={<Redirect url={`${config.AUTH_URL}/logout?backurl=${window.location.protocol}//${window.location.host}`} />} />
            <Route path="*" element={<Error />} />
            </>
          )}
        </Routes>
    </>
  );
};
