import { userAPI } from '../../api';
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from 'js-cookie';

export const Notifications = ({ setNotif }) => {
  const [notifs, setNotifs] = useState([]);
  const navigate = useNavigate();
  const token = Cookies.get("token");

  const deleteNotif = (e) => {
    const notif_id = e.currentTarget.id;
    const form = {
      notif_id: notif_id
    };
    
    userAPI
      .post(`/user/deleteNotif`, form, {headers: {Authorization: token}})
      .then((res) => {
        setNotifs(notifs.filter((obj) => obj.id != notif_id));
      })
      .catch((error) => {
        alert("Error");
      });
  };

  const handleDelete = (e)=> {
    if (window.confirm('¿Seguro que desea eliminar esta solicitud?')) {
      const notif_id = e.currentTarget.id;
      const form = {
        notif_id: notif_id,
        action: 'delete'
      };

      userAPI
        .post(`/user/resolveRequest`, form, {headers: {Authorization: token}})
        .then((res) => {
          setNotifs(notifs.filter((obj) => obj.id != notif_id));
        })
        .catch((error) => {
          alert("Error");
        });
    }
  }

  const handleAddContact = (e)=> {
    const notif_id = e.currentTarget.id;
    const form = {
      notif_id: notif_id,
      action: 'add'
    };
    
    userAPI
      .post(`/user/resolveRequest`, form, {headers: {Authorization: token}})
      .then((res) => {
        setNotifs(notifs.filter((obj) => obj.id != notif_id));
      })
      .catch((error) => {
        alert("Error");
      });
  }

  const markAsRead = (e) => {
    let id_list = notifs.reduce((acc, cur) => {
      if (cur.viewed == 0)
        acc = acc == '' ? acc + cur.id : acc + ', ' + cur.id;
      return acc;
    }, '');

    const form = {
      notif_list: id_list
    };
    
    if (id_list != '') {
      userAPI
      .post(`/user/markNotifsAsRead`, form, {headers: {Authorization: token}})
      .then((res) => {
        console.log("Marked as read");
        setNotif(0);
      })
      .catch((error) => {
        console.log("Error");
      });
    }
  };

  useEffect(() => {
    userAPI
      .get(`/user/allNotifications`, {headers: {Authorization: token}})
      .then((res) => {
        if (res.data) {
          setNotifs(res.data.result);
        }
      })
      .catch((error) => {
        console.log("Error");
      });
  }, []);

  useEffect(() => {
    const timer = setTimeout(markAsRead, 3000);
    return () => clearTimeout(timer);
  }, [notifs]);

  const goGreenhouse = (e) => {
    navigate(`/otherGreenhouse/${e.currentTarget.id}`);
  };

  const goChat = (e) => {
    navigate(`/chatwith/${e.currentTarget.id}`);
  };

  return (
    <main className='main mainheight'>
      <div className='container'>

        <div className='row'>
          <div className="col text-center title-margin">
            <h4><span className="text-gradient-green-orange">Notificaciones</span></h4>
          </div>
        </div>

        <div className='row'>
          {notifs == 0 && <div className='col-12 text-center'>No hay notificaciones</div>}
          {notifs ?
            // Hacemos un filtro en el .map de los elementos que coincidan con la busqueda
            notifs.map((obj, index) => {
              let viewClass = obj.viewed == 0
                ? obj.type == 0
                  ? 'nonView newReq'
                  : obj.type == 1
                    ? 'nonView newMsg'
                    : 'nonView newGh' 
                : 'view';
              let data = JSON.parse(obj.data);
              
              return (
                <div className="col-12 col-md-12 col-lg-12" key={obj.id}>
                  <div className="card shadow-none mb-3">
                    <div className={`card-body ${viewClass}`}>
                      <div className="row align-items-center">
                        <div
                          id={obj.type == 2 ? data.gh_id : obj.type == 1 ? data.sender_id : obj.id}
                          className={`col-9 text-start${obj.type != 0 ? ' clickable' : ''}`}
                          onClick={obj.type == 2 ? goGreenhouse : obj.type == 1 ? goChat : null}
                        >
                          <div className='notifdate'>
                            {obj.type == '0' && <i className="bi bi-people-fill text-success ba-xl fs-6 me-2"></i>}
                            {obj.type == '1' && <i className="bi bi-chat-text text-primary ba-xl fs-6 me-2"></i>}
                            {obj.type == '2' && <i className="bi bi-flower1 text-warning ba-xl fs-6 me-2"></i>}
                            {new Date(obj.time).toLocaleString()}
                          </div>
                          <span className="mb-3">
                            <strong>{obj.title}</strong>
                            {obj.type == '0' && <em> te ha enviado una solicitud de contacto.</em>}
                            {obj.type == '2' && (
                              <><em> te ha asignado el invernadero </em><strong>{data.gh_name}</strong>.</>
                            )}
                            {obj.type == '1' && <em> te ha enviado nuevos mensajes. </em>}
                          </span>
                        </div>

                        {obj.type == 0 && 
                          <div className="col-3 text-end">
                            <a
                              className='m-1'
                              href="#"
                              title='Añadir contacto'
                              onClick={handleAddContact}
                              id={obj.id}
                            >
                              <i className="bi bi-plus-circle ba-xl text-success"></i>
                            </a>
                            <a
                              className='m-1'
                              href="#"
                              title='Eliminar solicitud'
                              onClick={handleDelete}
                              id={obj.id}
                            >
                              <i className="bi bi-x-circle ba-xl text-danger"></i>
                            </a>
                          </div>
                        }

                        {obj.type != 0 && 
                          <div className="col-3 text-end">
                            <a
                              className='m-1'
                              href="#"
                              title='Eliminar notificación'
                              onClick={deleteNotif}
                              id={obj.id}
                            >
                              <i className="bi bi-x-circle ba-xl text-danger"></i>
                            </a>
                          </div>
                        }

                      </div>
                    </div>
                  </div>
                </div>
              )

            }) : null}
        </div>

      </div>
    </main>
  );
};
