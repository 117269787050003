import axios from 'axios';
import config from './config.json';

const mainAPI = axios.create({
    baseURL: "http://localhost:4000",
});

const userAPI  = axios.create({
    baseURL: config.USER_API_URL
});

const greenhouseAPI = axios.create({
    baseURL: config.GREENHOUSE_API_URL
});

const messengerAPI = axios.create({
    baseURL: config.MESSENGER_API_URL
});

const boardAPI = axios.create({
    baseURL: config.BOARD_API_URL
});

mainAPI.defaults.baseURL = config.MAIN_API_URL;
userAPI.defaults.baseURL = config.USER_API_URL;
greenhouseAPI.defaults.baseURL = config.GREENHOUSE_API_URL;
messengerAPI.defaults.baseURL = config.MESSENGER_API_URL;
boardAPI.defaults.baseURL = config.BOARD_API_URL;

export {mainAPI, userAPI, greenhouseAPI, messengerAPI, boardAPI};
